import { StyleSheet, Dimensions, Platform } from "react-native"
import cColors from "../styles/componentColors"

export const { width } = Dimensions.get('window')
export const inputStyle = {
    paddingTop: 14,
    paddingBottom: 14,
    marginLeft: 12,
    marginRight: 12,
    paddingLeft: 0,
    color: 'white',
    fontSize: Platform.OS === 'web' ? 20 : 24,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderStyle: 'solid',
    borderBottomColor: cColors.BOTTOM_BORDER_COLOR,
};

export const styles = StyleSheet.create({
    input: inputStyle,
    label: {
        color: cColors.AUTH_LABEL_TEXT_COLOR,
        paddingBottom: 18,
        paddingTop: 20,
        paddingLeft: 12,
        textAlign: 'left',
        width,
        fontSize: Platform.OS === 'web' ? 24 : 30
    },
    subLabel: {
        paddingHorizontal: 12,
        color: cColors.AUTH_SUBLABEL_TEXT_COLOR,
        paddingBottom: 10,
        fontSize: 16,
    },
    container: {
        flex: 1,
        backgroundColor: cColors.PAGE_BG_COLOR,
    },
    resetContainer: {
        flex: 1,
        backgroundColor: cColors.PAGE_BG_COLOR,
        alignItems: 'center'
    },
    webContainer: {
        flex: 1,
        backgroundColor: cColors.PAGE_BG_COLOR,
        display: 'flex',
        alignItems: 'center'
    },
    webScroll: {
        width: 410
    },
    containerMobile: {
        width: width
    },
    button: {
        paddingHorizontal: 20,
        paddingVertical: 20
    },
    buttonText: {
        color: cColors.BUTTON_TEXT_COLOR,
        fontSize: Platform.OS === 'web' ? 18 : 20,
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonTextWhite: {
        color: 'white',
        fontSize: 20,
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    forgotPasswordContainer: {
        paddingVertical: 20,
        alignSelf: 'center',
        width: 200
    },
    padding: {
        paddingVertical: 10
    },
    smallPadding: {
        paddingVertical: 5
    },
    tryButtonContainer: {
        // bottom: 100,
        // position: 'absolute',
        alignSelf: 'center',
        paddingVertical: 20
    },
    page: {
        width: Platform.OS === 'android' || Platform.OS === 'ios' ? width : '100%',
        backgroundColor: cColors.PAGE_BG_COLOR,
        paddingTop: 0,
        display: 'flex',
        paddingHorizontal: 0,
        flex: 1,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-start'
    },
    resetPage: {
        width: Platform.OS === 'android' || Platform.OS === 'ios' ? width : '420px',
        backgroundColor: cColors.PAGE_BG_COLOR,
        paddingTop: 0,
        display: 'flex',
        paddingHorizontal: 0,
        flex: 1,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-start'
    },
    continueButtonText: {
        // backgroundColor: 'green',
        fontSize: Platform.OS === 'web' ? 14 : 16,
        paddingHorizontal: 4,
        // justifyContent: 'flex-end',
        flexDirection: 'row',
        // backgroundColor: 'red',
        textAlign: 'left',
        fontWeight: '500',
        display: 'flex'
    },
    errorLabel: {
        color: 'red',
        fontSize: 18,
        paddingHorizontal: 12,
        paddingVertical: 12
    }
})
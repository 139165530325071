import { Platform, ReturnKeyTypeIOS, ReturnKeyTypeOptions, TextInput as RNTextInput } from "react-native";
import React, { useRef } from "react";
import { forwardRef } from "react";
import { useEffect } from "react";

interface TextInputProps {
    style?: any;
    onBlur?: (e: any) => void
    onChangeText?: (value: string) => void;
    defaultValue?: string;
    multiline?: boolean;
    placeholderTextColor?: string;
    placeholder?: string;
    numberOfLines?: number;
    editable?: boolean;
    autoFocus?: boolean;
    value?: string;
    onSubmitEditing?: (e: any) => void
    onKeyPress?: (e: any) => void;
    returnKeyType?: ReturnKeyTypeOptions,
    secureTextEntry?: boolean,
    onFocus?: () => void,
    autoComplete?: 'birthdate-day'
    | 'birthdate-full'
    | 'birthdate-month'
    | 'birthdate-year'
    | 'cc-csc'
    | 'cc-exp'
    | 'cc-exp-day'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-number'
    | 'email'
    | 'gender'
    | 'name'
    | 'name-family'
    | 'name-given'
    | 'name-middle'
    | 'name-middle-initial'
    | 'name-prefix'
    | 'name-suffix'
    | 'password'
    | 'password-new'
    | 'postal-address'
    | 'postal-address-country'
    | 'postal-address-extended'
    | 'postal-address-extended-postal-code'
    | 'postal-address-locality'
    | 'postal-address-region'
    | 'postal-code'
    | 'street-address'
    | 'sms-otp'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-device'
    | 'username'
    | 'username-new'
    | 'off'
    | undefined
    autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined,
    autoCorrect?: boolean,
    isNewPassword?: boolean,
    isCurrentPassword?: boolean,
}

export function NativeTextInput({ style, placeholder, onChangeText, defaultValue, multiline, autoFocus, editable, placeholderTextColor, onSubmitEditing, onKeyPress, value, returnKeyType, secureTextEntry, onFocus, numberOfLines, autoComplete, autoCapitalize, autoCorrect, onBlur }: TextInputProps, ref: any) {
    return <RNTextInput
        ref={ref}
        value={value}
        style={style}
        placeholderTextColor={placeholderTextColor}
        placeholder={placeholder}
        onChangeText={onChangeText}
        defaultValue={defaultValue}
        numberOfLines={numberOfLines}
        multiline={multiline}
        editable={editable}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        onSubmitEditing={onSubmitEditing}
        returnKeyType={returnKeyType}
        secureTextEntry={secureTextEntry}
        onFocus={onFocus}
        onBlur={onBlur}
        autoCorrect={autoCorrect}
    />
}

export function WebInputStyles(props: { placeholderTextColor?: string }) {
    return <style>{`
        input::placeholder {
            color: ${props.placeholderTextColor}
        }
        textarea::placeholder {
            color: ${props.placeholderTextColor}
        }
        `}
    </style>
}

export function WebTextInput({ style: propStyle, placeholder, onChangeText, defaultValue, multiline, autoFocus, editable, placeholderTextColor, onSubmitEditing, value, secureTextEntry, onFocus, isNewPassword = false, isCurrentPassword = false, onBlur }: TextInputProps, outerRef: any) {
    const ref = outerRef ?? useRef() as React.MutableRefObject<any>;
    useEffect(() => {
        const enterFunc = (e: any) => {
            if (onSubmitEditing && e.code === 'Enter') onSubmitEditing(e)
        }

        const addEvent = !!ref && !!ref.current && !!onSubmitEditing
        if (addEvent) {
            ref.current.addEventListener("keyup", enterFunc)
        }
        return () => {
            if (addEvent && ref.current) {
                ref.current.removeEventListener('keyup', enterFunc)
            }
        }
    }, [onSubmitEditing, ref])

    const style = { ...propStyle, backgroundColor: 'transparent', outline: 'none' }

    if (multiline) {
        return <>
            <WebInputStyles placeholderTextColor={placeholderTextColor} />
            <textarea
                ref={ref}
                style={style}
                placeholder={placeholder}
                onChange={(e) => { onChangeText && onChangeText(e.target.value) }}
                defaultValue={defaultValue}
                autoFocus={autoFocus}
            >
            </textarea>
        </>
    }
    return <>
        <WebInputStyles placeholderTextColor={placeholderTextColor} />
        <input
            ref={ref}
            style={style}
            type={secureTextEntry ? 'password' : 'text'}
            value={value}
            placeholder={placeholder}
            onChange={(e) => { onChangeText && onChangeText(e.target.value) }}
            defaultValue={defaultValue}
            // onKeyUp={onKeyPress}
            autoFocus={autoFocus}
            onFocus={onFocus}
            autoComplete={isNewPassword ? "new-password" : isCurrentPassword ? 'current-password' : undefined}
            onBlur={onBlur}
        />
    </>
}

export default (Platform.select({
    web: forwardRef(WebTextInput),
    default: forwardRef(NativeTextInput)
}))

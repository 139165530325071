import Task, { MappedTasks, TaskType } from '../../types/Task';
import { combineReducers } from 'redux';
import { PublicTasksActions } from './publicTasksActions'
const TASKS_INITIAL_STATE = {}

const forTodayTasksReducer = (
  tasks: MappedTasks = TASKS_INITIAL_STATE as MappedTasks,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case PublicTasksActions.GET_PUBLIC_FOR_TODAY_TASKS_SUCCESS:
      return action.payload.tasks as MappedTasks
    default:
      return tasks as MappedTasks;
  }
};

const saveForLaterTasksReducer = (
  tasks: MappedTasks = TASKS_INITIAL_STATE as MappedTasks,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case PublicTasksActions.GET_PUBLIC_SAVE_FOR_LATER_TASKS_SUCCESS:
      return action.payload.tasks as MappedTasks
    default:
      return tasks as MappedTasks;
  }
};

export default combineReducers({
  "PublicForToday": forTodayTasksReducer,
  "PublicSaveForLater": saveForLaterTasksReducer,
});

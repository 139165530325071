import Task, { MappedTasks } from "../../types/Task";

export enum PublicTasksActions {
  GET_PUBLIC_TASKS_START = "GET_PUBLIC_TASKS_START",
  GET_PUBLIC_SAVE_FOR_LATER_TASKS_SUCCESS = "GET_PUBLIC_SAVE_FOR_LATER_TASKS_SUCCESS",
  GET_PUBLIC_FOR_TODAY_TASKS_SUCCESS = "GET_PUBLIC_FOR_TODAY_TASKS_SUCCESS",
  GET_PUBLIC_TASKS_ERROR = "GET_PUBLIC_TASKS_ERROR",
}

export const getPublicSaveForLaterTasksSuccessAction = function (tasks: MappedTasks) {
  return {
    type: PublicTasksActions.GET_PUBLIC_SAVE_FOR_LATER_TASKS_SUCCESS,
    payload: { tasks }
  };
};

export const getPublicForTodayTasksSuccessAction = function (tasks: MappedTasks) {
  return {
    type: PublicTasksActions.GET_PUBLIC_FOR_TODAY_TASKS_SUCCESS,
    payload: { tasks }
  };
};

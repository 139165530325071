import { registerRootComponent } from 'expo';
import App from './App';
import { appWindow, PhysicalSize } from '@tauri-apps/api/window';
if (window.__TAURI__) {
    appWindow.setMinSize(new PhysicalSize(1000, 800))
        .then(() => {
            // console.log("success")
        }).catch(() => {
            // console.log("error")
        })
}
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);

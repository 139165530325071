import publicTasksReducer from "./tasks/publicTasksReducer";
import publicContextReducer from "./contexts/publicContextReducer";

import { combineReducers, createStore } from "redux";
const publicReducer = combineReducers({
  allTasks: publicTasksReducer,
  contexts: publicContextReducer
});

export const publicStore = createStore(publicReducer);

import { useEffect, useState } from "react";
import { View, Text, Platform, Dimensions } from "react-native";
import tw from "twrnc";
import ItemsFlatList from "../components/ItemsFlatList";
import { loadPublicTasks } from "../services/publicTaskService";
import { loadPublicContext } from "../services/publicContextService"
import { connect } from 'react-redux';

import { useRoute } from '@react-navigation/native';
import colors from "../colors";
import { MappedContexts } from "../types/Context";

function PublicListScreen(props: { navigation: any, contexts: MappedContexts }) {
  const route = useRoute()
  const publicContextId = route.params.publicContextId || ""
  const [page, setPage] = useState<"404" | "Loading" | "Success">("Loading")
  const [isEmptyUpcoming, setIsEmptyUpcoming] = useState<"Loading" | "Empty" | "NotEmpty">("Loading")
  useEffect(() => {
    loadPublicContext(publicContextId)
      .then(() => {
        loadPublicTasks(publicContextId)
          .then(a => {
            setPage('Success')
          })
          .catch(e => {
            setPage('404')
          })
      })
      .catch(() => {
        setPage('404')
      })
  }, [])
  if (props.contexts[publicContextId]) {
    props.navigation.setOptions({ title: `${props.contexts[publicContextId].name} - minilist` })
  }
  return <>
    {page === '404' && <View style={tw.style(`w-full flex flex-col px-4 py-4 justify-center items-center`, { height: "100vh", backgroundColor: colors.CHARCOAL })}>
      <Text style={{ color: colors.GAINSBORO, fontSize: 20 }}>{"404 Not Found"}</Text>
    </View>}

    {page === 'Loading' && <View style={tw.style(`w-full flex flex-col px-4 py-4 justify-center items-center`, { height: "100vh", backgroundColor: colors.CHARCOAL })}>
      <Text style={{ color: colors.GAINSBORO, fontSize: 20 }}>{"Loading"}</Text>
    </View>}

    {page === 'Success' && <>
      <View style={tw.style(`w-full flex flex-col px-4 py-4`, { height: "10vh", backgroundColor: colors.CHARCOAL })}>
        <Text style={{ color: colors.GAINSBORO, fontSize: 24 }}>{props.contexts[publicContextId] ? props.contexts[publicContextId].name : ""}</Text>
        <Text style={{ color: colors.GAINSBORO, fontSize: 12 }}>{props.contexts[publicContextId] ? `by ${props.contexts[publicContextId].created_by}` : ""}</Text>
      </View>
      <View
        style={tw.style(`w-full flex flex-row justify-center`, {
          height: "90vh",
          backgroundColor: colors.CHARCOAL
        })}
      >
        <div
          style={tw.style(`flex flex-col`, {
            width: 450,
          })}
        >
          {(isEmptyUpcoming === 'Loading' || isEmptyUpcoming === 'NotEmpty') && <div style={tw.style(`bg-red-200 h-4/12`, { backgroundColor: colors.CHARCOAL })}>
            <div style={tw.style(`py-2 px-4 mb-2`, {})}>
              <Text style={tw.style(`text-white text-xl`, { color: colors.GAINSBORO })}>{"Upcoming"}</Text>
            </div>
            <div style={tw.style(`py-2`, {})}>
              <ItemsFlatList
                paddingInCell
                navigation={{}}
                listType="PublicForToday"
                isEmptyCallback={(isEmpty: boolean) => {
                  setIsEmptyUpcoming(isEmpty ? "Empty" : "NotEmpty")

                }}
              />
            </div>
          </div>}
          <div style={tw.style(`h-8/12`, {
          })}>
            <div style={tw.style(`py-2 px-4 flex-1 mb-2`, {})}>
              <Text style={tw.style(`text-white text-xl`, { color: colors.GAINSBORO })}>{"Saved For Later"}</Text>
            </div>
            <div style={tw.style(`py-2`, {})}>
              <ItemsFlatList
                paddingInCell
                navigation={{}}
                listType="PublicSaveForLater"
              />
            </div>
          </div>
        </div>
      </View>{" "}
    </>}
  </>;
}

const mapStateToProps = function (state: any,) {
  return {
    contexts: state.contexts,
  }
}

export default connect(mapStateToProps)(PublicListScreen)


export enum ContextWidthsActions {
    SET_CONTEXT_WIDTHS = "SET_CONTEXT_WIDTHS",
    SET_CONTEXT_WIDTH = "SET_CONTEXT_WIDTH"
}


export const setContextWidthsAction = function (contexts: { [key: string]: number | string }) {
    return {
        type: ContextWidthsActions.SET_CONTEXT_WIDTHS,
        payload: contexts
    }
}

export const setContextWidthAction = function (contextId: string, width: number | string) {
    return {
        type: ContextWidthsActions.SET_CONTEXT_WIDTH,
        payload: {
            contextId,
            width
        }
    }
}

import { snakeCase } from 'snake-case'

export const toSnakeCaseKeys = function (obj: any) {
    const keys = Object.keys(obj);
    const snaked = {} as any

    for (let key of keys) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key]){
            snaked[snakeCase(key)] = toSnakeCaseKeys(obj[key])
        } else {
            snaked[snakeCase(key)] = obj[key]
        }
    }

    return snaked 
}

import React from 'react'
import Menu, { MenuItem, MenuItemTitle, Seperator } from './ContextMenu'
import { Platform } from "react-native";
import { connect } from 'react-redux';
import { resetTaskIdAction } from "../redux/visual/visualActions"
import { deleteTask, moveTaskToSaveForLaterAndReload } from '../services/tasksService';
import { MappedTasks } from '../types/Task';


const RightClickMenu = ({ taskId, resetTaskId, allTasks }: { taskId?: string, resetTaskId: () => void, allTasks: { [key: string]: MappedTasks } }) => {

  const task = taskId ? allTasks['Daily'][taskId] || allTasks['SaveForLater'][taskId] || allTasks['JustToday'][taskId] || undefined : undefined

  const resetTaskIdWrapper = () => {
    resetTaskId()
  }
  return <Menu
    taskId={taskId}
    resetTaskId={resetTaskIdWrapper}
  >
    {task && <MenuItemTitle label={"Task"} />}

    {task && task.type === "JUST_TODAY" && <MenuItem
      label={`Save For Later`}
      onClick={() => {
        moveTaskToSaveForLaterAndReload(taskId as string)
      }} />}
    {task && <MenuItem label={`Delete Task`}
      onClick={() => {
        deleteTask(taskId as string, { reloadSaveForLater: true })
      }} />}
    {task && <Seperator />}

    <MenuItem
      label="Reload"
      onClick={() => {
        location.reload();
      }}
    />
  </Menu>
}

const mapStateToProps = function (state: any) {
  return {
    taskId: state.visual.menu.taskId,
    allTasks: state.allTasks
  }
}

const mapDispatchToProps = function (dispatch: (action: any) => void) {
  return {
    resetTaskId: function () {
      dispatch(resetTaskIdAction())
    }
  }
}

const EmptyComponent = () => <></>

const ConditionalMenu = Platform.select({
  ios: EmptyComponent,
  android: EmptyComponent,
  native: EmptyComponent,
  default: RightClickMenu,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConditionalMenu)

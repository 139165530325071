export enum VisualActions {
    SET_TASK_ID_MENU = "SET_TASK_ID_MENU",
    REMOVE_TASK_ID_MENU = "REMOVE_TASK_ID_MENU",
    RESET_TASK_ID = "RESET_TASK_ID",
    TOGGLE_ENABLE_NOTIFICATION = "TOGGLE_ENABLE_NOTIFICATION",
}

export const openMenuWithTaskId = function (taskId: string) {
    return {
        type: VisualActions.SET_TASK_ID_MENU,
        payload: { taskId }
    }
}

export const removeMenuTaskId = function (taskId: string) {
    return {
        type: VisualActions.REMOVE_TASK_ID_MENU,
    }
}

export const resetTaskIdAction = function () {
    return {
        type: VisualActions.RESET_TASK_ID
    }
}
export const toggleEnableNotification = function (enableNotification: boolean) {
    return {
        type: VisualActions.TOGGLE_ENABLE_NOTIFICATION,
        payload: { enableNotification }
    }
}

import { Platform } from "react-native";
import * as SQLite from "expo-sqlite";
import * as FileSystem from 'expo-file-system';
import { WebSQLDatabase } from "expo-sqlite";
import Logger from "./external/logger";
import env from '../utils/env';

//@ts-ignore
const logger = new Logger(env.LOGGER_TOKEN)

export const writeDBFile = async (dbUri: string) => {
  // @ts-ignore
  if (!dbUri || !!window.__TAURI__ || Platform.OS === 'web') {
    return
  }

  if (!(await FileSystem.getInfoAsync(FileSystem.documentDirectory + 'SQLite')).exists) {
    await FileSystem.makeDirectoryAsync(FileSystem.documentDirectory + 'SQLite');
  }

  if (!(await FileSystem.getInfoAsync(FileSystem.documentDirectory + 'SQLite/db.db')).exists) {
    await FileSystem.copyAsync({
      from: dbUri,
      to: FileSystem.documentDirectory + 'SQLite' + '/' + 'db.db',
    });
  }
}

export const EMPTY_DB = {
  transaction: () => {
    return {
      executeSql: () => { },
    };
  },
};

export async function openDatabase() {

  if (Platform.OS === "web") {
    return EMPTY_DB
  }

  return SQLite.openDatabase('db.db', '3.0');
}


export const simpleTransaction: (db: WebSQLDatabase, query: string, params: (string | number)[]) => Promise<any | undefined> = (db: WebSQLDatabase, query: string, params: (string | number)[]) => {
  return new Promise((resolve, reject) => db.transaction(
    (tx) => {
      tx.executeSql(
        query,
        params,
        (_, output) => {
          resolve(output.rows._array)

        }
      );
    },
    (e) => {
      reject(e)
    },
    () => {
      // console.log("success")
    }))
}

const colors = {
    GAINSBORO: '#e0e0e2',
    GAINSBORO_MINUS_2: '#b3b3b5',

    MIDDLE_BLUE_GREEN: '#81d2c7',
    MIDDLE_BLUE_GREEN_DARKER: '#69b8b1',
    CHARCOAL: '#3a4454',
    CHARCOAL_BUT_DARKER: '#20242b',
    DARK_ELECTRICS_BLUE: '#53687e',
    LIGHT_PERIWINKLE_PLUS_3: '#D0D3E1',
    LIGHT_PERIWINKLE_PLUS_2: '#C7CBDB',
    LIGHT_PERIWINKLE_PLUS_1: '#BEC2D5',
    LIGHT_PERIWINKLE: '#b5bad0',
    LIGHT_PERIWINKLE_MINUS_1: '#979EBC',

    MIDNIGHT_BLUE: '#191970',
    MIDNIGHT_BLUE_MINUS_1: '#252B35',


    DELETE_RED: '#FF3C28'
}

export default colors

export const contextColors = {
    SKOBELOFF: '#297373',
    YELLOW_CRAYOLA: '#F9E784',
    COPPER_CRAYOLA: '#E58F65',
    INDIAN_RED: '#D05353',
    CADET_BLUE: '#58A4B0',
    SALMON: '#F38375',
    OLD_GOLD: '#BFAB25',
    MAROON_X_11: '#B81365',
    WISTERIA: '#BEA7E5',
    HONEYDEW: '#F1FAEE'
} as { [key: string]: string }

export const contextColorsByHex = {} as { [key: string]: string }

Object.keys(contextColors).forEach((colorName) => {
    contextColorsByHex[contextColors[colorName]] = colorName
})




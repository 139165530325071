import * as React from 'react'
import { Platform, TouchableWithoutFeedback, View, Text, Linking, StyleSheet } from "react-native"
import { open } from '@tauri-apps/api/shell';
import { A } from '@expo/html-elements';

interface ButtonProps {
    type: 'link' | 'button'
    href?: string
    onPress?: () => void
    label: string,
    color?: string,
    fontSize?: number | undefined
}

function WebButton({ type, href, onPress, label, color, fontSize = 20 }: ButtonProps) {
    // @ts-ignore
    const onPressFunc = type === 'link' && !!window.__TAURI__
        ? () => {
            open(href as string)
        }
        : onPress;

    // @ts-ignore
    if (type === 'link' && !window.__TAURI__) {
        return <A
            href={href}
            style={{
                color: color,
                fontSize: fontSize, 
                textAlign: 'center',
            }}>
            {label}
        </A>
    }

    return <TouchableWithoutFeedback onPress={onPressFunc} >
        <View style={{
            cursor: 'pointer'
        }}>
            <Text style={{
                color: color,
                fontSize: fontSize,
                textAlign: 'center'
            }}>{label}</Text>
        </View>
    </TouchableWithoutFeedback>
}

function DefaultButton({ type, href, onPress, label, color, fontSize = 20 }: ButtonProps) {
    const onPressFunc = type === 'link'
        ?
        () => {
            Linking.openURL(href as string)
        }
        : onPress

    return <TouchableWithoutFeedback onPress={onPressFunc} >
        <View style={{
            cursor: 'pointer'
        }}>
            <Text style={{
                color: color,
                fontSize: fontSize,
                textAlign: 'center'
            }}>{label}</Text>
        </View>
    </TouchableWithoutFeedback>
}


export default Platform.select({
    web: WebButton,
    default: DefaultButton
})

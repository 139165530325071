import * as React from 'react';
import { StyleSheet, AppState, AppStateStatus, Image, Platform, View } from 'react-native';
import TopBar from '../components/TopBar';
import { SafeAreaView } from 'react-native-safe-area-context';
import BottomButton from '../components/BottomButton';
import ItemsFlatList from '../components/ItemsFlatList';
import { loadAllTasks, moveUncheckedYesterdayTasksToSfl } from '../services/tasksService';
import { getUser, getUserMetaData, setDailyImportDate } from '../services/userService';
import IconBottomButton from '../components/IconBottomButton';
import { parseCurrentDateTimeIso } from '../utils/date';
import { delay } from '../utils/delay';
import Logger from '../services/external/logger';
import { loadAllContexts } from '../services/contextService';
import env from '../utils/env';
import * as Notifications from 'expo-notifications';
import { getEnabledNotificationState, registerForPushNotificationsAsync, schedulePushNotification } from '../utils/notifications';
import { appWindow } from "@tauri-apps/api/window";
import { store } from '../redux';
import { toggleEnableNotification } from '../redux/visual/visualActions';
import ContextBubblesTopList from '../components/ContextBubblesTopList';
import cColors from '../styles/componentColors'

// @ts-ignore
const logger = new Logger(env.LOGGER_TOKEN)

export default function Home(props: { navigation: any, route: any }) {
    const appState = React.useRef(AppState.currentState);
    const [appStateVisible, setAppStateVisible] = React.useState(appState.current);
    const [dailyTaskOpen, setDailyTaskOpen] = React.useState(false);
    React.useEffect(() => {
        // props.navigation.navigate('OnEnterScreen', { recentTasks: [] })

        if (Platform.OS === 'ios' || Platform.OS === 'android') {
            registerForPushNotificationsAsync().then(token => {
                getEnabledNotificationState()
                    .then((value) => {
                        store.dispatch(toggleEnableNotification(value))
                    })
            });
        }

        const fromPage = props.route.params ? props.route.params.fromPage : undefined
        if (fromPage === "login" || fromPage === "signup") {
            getUser()
                .then(() => { })
                .catch(e => {
                    console.log(e)
                })
        }
        const loadImportIfNeeded = () => {
            getUserMetaData().then((resp) => {
                if (fromPage === 'signup') {
                    setDailyImportDate()
                        .then(() => { })
                        .catch(e => console.log(e))
                } else {
                    const nowDate = parseCurrentDateTimeIso()
                    if (resp) {
                        if (!resp.last_daily_import_date || resp.last_daily_import_date !== nowDate) {
                            moveUncheckedYesterdayTasksToSfl()
                                .then(() => {
                                    props.navigation.navigate('OnEnterScreen', { recentTasks: resp.recent_tasks })
                                })
                                .catch(e => console.log(e))
                        }
                    } else {
                        console.log("resp is null")
                    }
                }
            }).catch(e => {
                console.log(e)
            })
        }
        const onReturnEvent = () => {
            loadAllTasks(false)
            loadAllContexts()
            loadImportIfNeeded()
            if (store.getState().visual.enableNotification) {
                Notifications.getPresentedNotificationsAsync()
                    .then((presentedNotifications) => {
                        if (presentedNotifications.length === 0) {
                            schedulePushNotification()
                        }
                    })
            }
        }

        const eventHandler = (nextAppState: AppStateStatus) => {
            if (
                appState.current.match(/inactive|background/) &&
                nextAppState === "active"
            ) {
                onReturnEvent()
            }
            appState.current = nextAppState;
            setAppStateVisible(appState.current);
        }

        const shortcutHandler = (event: any) => {
            if ((event.code === 'KeyN' && event.metaKey && !event.shiftKey) || (event.ctrlKey && event.code === 'KeyN' && !event.shiftKey)) {
                props.navigation.navigate('AddTask')
            } else if ((event.code === 'KeyN' && event.metaKey && event.shiftKey) || (event.ctrlKey && event.code === 'KeyN' && event.shiftKey)) {
                props.navigation.navigate('AddMultipleTasks')
            } else if ((event.code === 'KeyB' && event.metaKey) || (event.ctrlKey && event.code === 'KeyB')) {
                props.navigation.navigate('SaveForLaterTasksList')
            }
        }

        let unlisten: any = () => { };
        //@ts-ignore
        if (window.__TAURI__) {
            appWindow.onFocusChanged(({ payload: focused }) => {
                if (focused) {
                    onReturnEvent()
                }
            }).then(unlistenFunc => {
                unlisten = unlistenFunc
            }).catch(e => { })

            console.log(unlisten)

            document.body.addEventListener("keypress", shortcutHandler)
        }

        delay(40).then(() => {
            logger.log("LOADING_TASKS", "useEffect")
            loadAllTasks()
            loadAllContexts()
            loadImportIfNeeded()
        })

        const subscription = AppState.addEventListener("change", eventHandler);

        return () => {
            subscription.remove()
            //@ts-ignore
            if (window.__TAURI__) {
                unlisten()
                document.body.removeEventListener('keypress', shortcutHandler)
            }
        }
    }, [props.route])

    return (<SafeAreaView style={styles.container} edges={['left', 'right']}>
        <View style={{ zIndex: -20, position: 'absolute', width: '100%' }}>
            <ContextBubblesTopList isCache setContextIndex={() => { }} marginSide='left' contextIndex={null} navigation={props.navigation} />
        </View>
        <TopBar
            navigation={props.navigation}
            onPress={(expanded) => {
                setDailyTaskOpen(expanded)
            }} />


        <ItemsFlatList listType='JustToday' navigation={props.navigation} extraSpaceAtEnd paddingInCell={Platform.OS === 'web' ? 4 : false} marginTop={6} />

        {!dailyTaskOpen && <IconBottomButton
            isIcon
            side="left"
            singleTap={() => props.navigation.navigate('SaveForLaterTasksList')}>
            <Image
                source={require('../assets/import-icon.png')}
                fadeDuration={0}
                style={{ width: 26, height: 27 }}
            />
        </IconBottomButton>}

        {!dailyTaskOpen && <BottomButton
            singleTap={() => props.navigation.navigate('AddTask')}
            doubleTap={() => props.navigation.navigate('AddMultipleTasks')}
            text="Add Task"
        />}
    </SafeAreaView>)
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: cColors.HOME_CONTAINER_BG_COLOR,
    },
    goToAddTaskButton: {
        position: 'absolute',
        bottom: 30,
        alignSelf: 'center'
    },
    goToAddTaskButtonContent: {
        backgroundColor: cColors.BOTTOM_BUTTON_COLOR,
        height: 70,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        borderRadius: 9999,
    },
    goToAddTaskButtonText: {
        paddingHorizontal: 30 * 2,
        color: cColors.BOTTOM_BUTTON_COLOR,
        fontSize: 20,
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

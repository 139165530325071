import User, { UserMetaData } from "../../types/User"

export enum UserActions {
    START_USER_LOGIN_ACTION = "START_USER_LOGIN_ACTION",
    SUCCESSFUL_LOGIN_ACTION = "SUCCESSFUL_LOGIN_ACTION",
    SUCCESSFUL_LOGOUT_ACTION = "SUCCESSFUL_LOGOUT_ACTION",
    SUCCESSFUL_GET_USER_ACTION = "SUCCESSFUL_GET_USER_ACTION",
    SET_USER_META_DATA = "SET_USER_META_DATA"
}

export const startUserLoginAction = function (username: string) {
    return {
        type: UserActions.START_USER_LOGIN_ACTION,
        payload: username
    }
}

export const successfulLoginAction = function (token: string) {
    return {
        type: UserActions.SUCCESSFUL_LOGIN_ACTION,
        payload: token
    }
}
export const successfulLogoutAction = function () {
    return {
        type: UserActions.SUCCESSFUL_LOGOUT_ACTION
    }
}


export const successfulGetUser = function (user: User) {
    return {
        type: UserActions.SUCCESSFUL_GET_USER_ACTION,
        payload: user
    }
}

export const setMetaDataAction = function (user_data: UserMetaData) {
    return {
        type: UserActions.SET_USER_META_DATA,
        payload: user_data
    }
}
import { MappedContexts } from "../../types/Context"
import { PublicContextActions } from "./publicContextActions"

let contextsById: MappedContexts = {}
export default function contextReducer(state = contextsById, action: { type: string, payload?: any }) {
    switch (action.type) {
        case PublicContextActions.SUCCESS_PUBLIC_LOAD_CONTEXTS_ACTION:
            return { ...action.payload }
        default:
            return state
    }
}

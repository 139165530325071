import Task, { MappedTasks } from "../../types/Task"

export enum TasksActions {

    START_SAVE_TASK_ACTION = "START_SAVE_TASK_ACTION",
    SUCCESS_SAVE_TASK_ACTION = "SUCCESS_SAVE_TASK_ACTION",

    START_LOAD_DAILY_TASKS_ACTION = "START_LOAD_DAILY_TASKS_ACTION",
    SUCCESS_LOAD_DAILY_TASKS_ACTION = "SUCCESS_LOAD_DAILY_TASKS_ACTION",

    START_LOAD_JUST_TODAY_TASKS_ACTION = "START_LOAD_JUST_TODAY_TASKS_ACTION",
    SUCCESS_LOAD_JUST_TODAY_TASKS_ACTION = "SUCCESS_LOAD_JUST_TODAY_TASKS_ACTION",

    START_LOAD_JUST_YESTERDAY_TASKS_ACTION = "START_LOAD_JUST_YESTERDAY_TASKS_ACTION",
    SUCCESS_LOAD_JUST_YESTERDAY_TASKS_ACTION = "SUCCESS_LOAD_JUST_YESTERDAY_TASKS_ACTION",

    START_LOAD_SAVE_FOR_LATER_TASKS_ACTION = "START_LOAD_SAVE_FOR_LATER_TASKS_ACTION",
    SUCCESS_LOAD_SAVE_FOR_LATER_TASKS_ACTION = "SUCCESS_LOAD_SAVE_FOR_LATER_TASKS_ACTION",

    START_LOAD_SEARCH_RESULT_TASKS_ACTION = "START_LOAD_SEARCH_RESULT_TASKS_ACTION",
    SUCCESS_LOAD_SEARCH_RESULT_TASKS_ACTION = "SUCCESS_LOAD_SEARCH_RESULT_TASKS_ACTION",
    RESET_SEARCH_RESULT_TASKS_ACTION = "RESET_SEARCH_RESULT_TASKS_ACTION",

    START_SAVE_FOCUSED_TASK_ACTION = "START_SAVE_FOCUSED_TASK_ACTION",
    SUCCESS_SAVE_FOCUSED_TASK_ACTION = "SUCCESS_SAVE_FOCUSED_TASK_ACTION",
    START_LOAD_FOCUSED_TASK_ACTION = "START_LOAD_FOCUSED_TASK_ACTION",
    SUCCESS_LOAD_FOCUSED_TASK_ACTION = "SUCCESS_LOAD_FOCUSED_TASK_ACTION",

    SET_FOCUS_TASK_ID_ACTION = "SET_FOCUS_TASK_ID_ACTION",

    SET_CHECK_IN_STATE_ACTION = "SET_CHECK_IN_STATE_ACTION",
    START_UPDATE_CHECK_STATE_ACTION = "START_UPDATE_CHECK_STATE_ACTION",
    SUCCESS_UPDATE_CHECK_STATE_ACTION = "SUCCESS_UPDATE_CHECK_STATE_ACTION",

    START_MOVE_TASK_TO_JUST_TODAY_ACTION = "START_MOVE_TASK_TO_JUST_TODAY_ACTION",
    START_MOVE_TASKS_TO_JUST_TODAY_ACTION = "START_MOVE_TASKS_TO_JUST_TODAY_ACTION",
    SUCCESS_MOVE_TASKS_TO_JUST_TODAY_ACTION = "SUCCESS_MOVE_TASKS_TO_JUST_TODAY_ACTION",
    SUCCESS_MOVE_TASK_TO_JUST_TODAY_ACTION = "SUCCESS_MOVE_TASK_TO_JUST_TODAY_ACTION",

    START_MOVE_TASK_TO_SAVE_FOR_LATER_ACTION = "START_MOVE_TASK_TO_SAVE_FOR_LATER_ACTION",
    SUCCESS_MOVE_TASK_TO_SAVE_FOR_LATER_ACTION = "SUCCESS_MOVE_TASK_TO_SAVE_FOR_LATER_ACTION",
    
    START_MOVE_TASKS_TO_SAVE_FOR_LATER_ACTION = "START_MOVE_TASKS_TO_SAVE_FOR_LATER_ACTION",
    SUCCESS_MOVE_TASKS_TO_SAVE_FOR_LATER_ACTION = "SUCCESS_MOVE_TASKS_TO_SAVE_FOR_LATER_ACTION",

    START_DELETE_TASK_ACTION = "START_DELETE_TASK_ACTION",
    SUCCESS_DELETE_TASK_ACTION = "SUCCESS_DELETE_TASK_ACTION",
    DELETE_TASK_FROM_SEARCH_RESULTS_ACTION = "DELETE_TASK_FROM_SEARCH_RESULTS_ACTION"
}


export const startSaveTaskAction = function () {
    return {
        type: TasksActions.START_SAVE_TASK_ACTION
    }
}

export const successSaveTaskAction = function (task: Task) {
    return {
        type: TasksActions.SUCCESS_SAVE_TASK_ACTION,
        payload: { task }
    }
}

export const startLoadDailyTasksAction = function () {
    return {
        type: TasksActions.START_LOAD_DAILY_TASKS_ACTION,
    }
}
export const successLoadDailyTasksAction = function (tasks: MappedTasks, disallowSort: boolean) {
    return {
        type: TasksActions.SUCCESS_LOAD_DAILY_TASKS_ACTION,
        payload: { tasks, disallowSort }
    }
}

export const startJustTodayTasksAction = function () {
    return {
        type: TasksActions.START_LOAD_JUST_TODAY_TASKS_ACTION,
    }
}
export const successJustTodayTasksAction = function (tasks: MappedTasks, disallowSort: boolean) {
    return {
        type: TasksActions.SUCCESS_LOAD_JUST_TODAY_TASKS_ACTION,
        payload: { tasks, disallowSort }
    }
}

export const startLoadJustYesterdayTasksAction = function () {
    return {
        type: TasksActions.START_LOAD_JUST_YESTERDAY_TASKS_ACTION,
    }
}
export const successLoadJustYesterdayTasksAction = function (tasks: MappedTasks) {
    return {
        type: TasksActions.SUCCESS_LOAD_JUST_YESTERDAY_TASKS_ACTION,
        payload: { tasks }
    }
}

export const startLoadSaveForlaterTasksAction = function () {
    return {
        type: TasksActions.START_LOAD_SAVE_FOR_LATER_TASKS_ACTION,
    }
}
export const successLoadSaveForlaterTasksAction = function (tasks: MappedTasks) {
    return {
        type: TasksActions.SUCCESS_LOAD_SAVE_FOR_LATER_TASKS_ACTION,
        payload: { tasks }
    }
}


export const startSaveFocusedTaskAction = function () {
    return {
        type: TasksActions.START_SAVE_FOCUSED_TASK_ACTION,
    }
}
export const successSaveFocusedTaskAction = function () {
    return {
        type: TasksActions.START_SAVE_FOCUSED_TASK_ACTION,
    }
}
export const startLoadFocusedTasksAction = function () {
    return {
        type: TasksActions.START_LOAD_FOCUSED_TASK_ACTION,
    }
}
export const successLoadFocusedTaskTasksAction = function (focusedTaskId: string) {
    return {
        type: TasksActions.SUCCESS_LOAD_FOCUSED_TASK_ACTION,
        payload: { focusedTaskId }
    }
}

export const startLoadSearchResultTasksAction = function () {
    return {
        type: TasksActions.START_LOAD_SEARCH_RESULT_TASKS_ACTION,
    }
}

export const successLoadSearchResultTasksAction = function (tasks: MappedTasks) {
    return {
        type: TasksActions.SUCCESS_LOAD_SEARCH_RESULT_TASKS_ACTION,
        payload: { tasks }
    }
}

export const setFocusTaskIdTasksAction = function (focusedTaskId: string) {
    return {
        type: TasksActions.SET_FOCUS_TASK_ID_ACTION,
        payload: { focusedTaskId }
    }
}

export const setCheckInStateAction = function (checkState: boolean, taskId: string) {
    return {
        type: TasksActions.SET_CHECK_IN_STATE_ACTION,
        payload: { checkState, taskId }
    }
}
export const startUpdateStateChangeAction = function (checkState: boolean, taskId: string) {
    return {
        type: TasksActions.START_UPDATE_CHECK_STATE_ACTION,
        payload: { checkState, taskId }
    }
}
export const successUpdateStateChangeAction = function (checkState: boolean) {
    return {
        type: TasksActions.SUCCESS_UPDATE_CHECK_STATE_ACTION,
        payload: { checkState }
    }
}


export const startMoveTasksToJustTodayAction = function (taskIds: string[]) {
    return {
        type: TasksActions.START_MOVE_TASKS_TO_JUST_TODAY_ACTION,
        payload: { taskIds }
    }
}
export const successMoveTasksToJustTodayAction = function (taskId: string[]) {
    return {
        type: TasksActions.SUCCESS_MOVE_TASKS_TO_JUST_TODAY_ACTION,
        payload: { taskId }
    }
}

export const startMoveTasksToSaveForLaterAction = function (taskIds: string[]) {
    return {
        type: TasksActions.START_MOVE_TASKS_TO_SAVE_FOR_LATER_ACTION,
        payload: { taskIds }
    }
}
export const startMoveTaskToSaveForLaterAction = function (taskId: string) {
    return {
        type: TasksActions.START_MOVE_TASK_TO_SAVE_FOR_LATER_ACTION,
        payload: { taskId }
    }
}
export const successMoveTaskToSaveForLaterAction = function (taskId: string) {
    return {
        type: TasksActions.SUCCESS_MOVE_TASK_TO_SAVE_FOR_LATER_ACTION,
        payload: { taskId }
    }
}
export const successMoveTasksToSaveForLaterAction = function (taskIds: string[]) {
    return {
        type: TasksActions.SUCCESS_MOVE_TASKS_TO_SAVE_FOR_LATER_ACTION,
        payload: { taskIds }
    }
}

export const startDeleteTaskAction = function (taskId: string) {
    return {
        type: TasksActions.START_MOVE_TASK_TO_SAVE_FOR_LATER_ACTION,
        payload: { taskId }
    }
}
export const deleteTaskFromSearchResultsAction = function (taskId: string) {
    return {
        type: TasksActions.DELETE_TASK_FROM_SEARCH_RESULTS_ACTION,
        payload: { taskId }
    }
}
export const successDeleteTaskAction = function (taskId: string) {
    return {
        type: TasksActions.SUCCESS_MOVE_TASK_TO_SAVE_FOR_LATER_ACTION,
        payload: { taskId }
    }
}
export const resetSearchResultTaskAction = function () {
    return {
        type: TasksActions.RESET_SEARCH_RESULT_TASKS_ACTION,
    }
}




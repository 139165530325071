import { Platform, } from "react-native";
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { warningToast } from './toast';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { FlatList } from "react-native-gesture-handler";

export async function schedulePushNotification() {
  if (Platform.OS !== 'web') {
    await Notifications.scheduleNotificationAsync({
      content: {
        title: "Making progress?",
        body: 'You still have tasks that you want to complete today.',
        data: { data: "" },
      },
      trigger: { seconds: 2 },
    });
  }
}

export async function registerForPushNotificationsAsync(): Promise<string | undefined> {
  let token;

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      warningToast('Failed to get push token for push notification!', 'bottom');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    console.log(token);
  } else {
    warningToast('Must use physical device for Push Notifications', 'bottom');
  }

  return token;
}


export async function getEnabledNotificationState(): Promise<boolean> {
  try {
    let val = await AsyncStorage.getItem('enableNotification').then((value) => {
      if (value !== null) {
        return value === 'true'
      } else {
        return false
      }
    })
    return val
  } catch (e) {
    return false
  }
}

export async function storeEnabledNotificationState(enableNotification: boolean) {
  try {
    await AsyncStorage.setItem('enableNotification', enableNotification ? "true" : "false")
      .then((value) => {
        console.log(value)
      })
  } catch (e) {
  }
}
import { Platform, StyleSheet } from "react-native"
import { HOST } from "../api";
import Task from '../types/Task';
import TaskInformationView from "../components/TaskInformationView";
import React, { useRef } from "react";
import { loadAllTasks } from "../services/tasksService";
import { store } from "../redux";
import ModalContainer from "../components/ModalContainer";

export default (props: { navigation?: any, route: any }) => {

    const { allTasks, user: { token } } = store.getState()

    const { taskId } = props.route.params

    const infoViewRef = useRef<any>();

    const saveTask = (task: Task, userId: string) => fetch(`${HOST}/tasks/${task.id}`, {
        method: "PUT",
        body: JSON.stringify(task),
        headers: {
            "Content-Type": "application/json",
            'Authorization': `${token}`
        }
    })
        .then(res => res.json())
        .catch(e => {

        })


    return <ModalContainer
        navigation={props.navigation}
        enableRightButton={true}
        rightButtonText={"Save"}
        rightButtonWidth={Platform.OS === 'ios' ? 55 : 80}
        rightButtonOnClick={() => {
            if (infoViewRef && infoViewRef.current) {
                infoViewRef.current.saveTask()
            }
        }}
    >
        <TaskInformationView
            ref={infoViewRef}
            navigation={props.navigation}
            mode="edit"
            task={allTasks['Daily'][taskId] || allTasks['SaveForLater'][taskId] || allTasks['JustToday'][taskId] || undefined}
            saveTask={(task) => saveTask(task, token)}
            afterButtonPress={(task) => {
                if (props.navigation) {
                    props.navigation.goBack()
                }
                loadAllTasks()
            }}
        />
    </ModalContainer>
}

const styles = StyleSheet.create({

})

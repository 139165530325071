import { getOS } from '../utils/env';

export function adjustWeb() {
    const os = getOS()
    const titlebarEl = document.getElementById("titlebar")
    if (!titlebarEl) {
        return
    }
    // @ts-ignore
    if (!!window && !!window.__TAURI__ && os.platform == 'darwin') {
        // titlebarEl.style.backgroundColor = "#252B35"
        titlebarEl.style.display = 'block'
    }
}

export function setTopBarColor() {
    const os = getOS()
    const titlebarEl = document.getElementById("titlebar")
    if (!titlebarEl) {
        return
    }

    // @ts-ignore
    if (!!window && !!window.__TAURI__ && os.platform == 'darwin') {
        titlebarEl.style.backgroundColor = "#252B35"
    }
}
import React from "react";
import { Platform } from "react-native";
import cColors from "../styles/componentColors";
import { Picker } from '@react-native-picker/picker';
import Select, { StylesConfig } from 'react-select'

export interface SelectOption {
    value: string,
    label: string
}

export interface TypeSelectProps {
    placeholder?: string,
    options: SelectOption[]
    onValueChange: (value: any) => void,
    selected: SelectOption
}

export const optionsMap = (options: SelectOption[]) => {
    let map = {} as { [key: string]: SelectOption };
    for (let option of options) {
        map[option.value || ''] = option
    }
    return map
}

const MobileTypeSelect = ({ options, onValueChange, selected }: TypeSelectProps) => {
    const optionMap = optionsMap(options);
    return <Picker
        style={{ color: 'white' }}
        selectedValue={selected.value}
        onValueChange={(itemValue, itemIndex) => onValueChange(optionMap[itemValue])}
        mode="dialog"
        dropdownIconColor={"white"}>
        {options.map(opt => <Picker.Item key={opt.value} label={opt.label} value={opt.value}
            color={
                Platform.OS === 'ios'
                    ? cColors.TASK_TYPE_SELECT_ITEM_MOBILE_COLOR_IOS
                    : cColors.TASK_TYPE_SELECT_ITEM_MOBILE_COLOR_NOT_IOS} />)}
    </Picker>;
};

const WebTypeSelect = ({ options, onValueChange, selected }: TypeSelectProps) => {
    const optionMap = optionsMap(options);
    const customStyles = {
        menu: (provided: any, state: any) => ({
            ...provided,
            // width: state.selectProps.width,
            color: state.selectProps,
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: cColors.TASK_TYPE_SELECT_WEB_BG_COLOR,
            borderRadius: 16,
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            borderRadius: 16,
            backgroundColor: 'transparent',
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            boxShadow: state.isFocused ? 'none' : 'none',
            border: state.isFocused ? `1px solid ${cColors.TASK_TYPE_SELECT_WEB_CONTROL_FOCUSED_BORDER_COLOR}` : `1px solid ${cColors.TASK_TYPE_SELECT_WEB_CONTROL_UNFOCUSED_BORDER_COLOR}`,
            "&:hover": {
                border: state.isFocused ? `1px solid ${cColors.TASK_TYPE_SELECT_WEB_CONTROL_FOCUSED_ONHOVER_BORDER_COLOR}` : `1px solid ${cColors.TASK_TYPE_SELECT_WEB_CONTROL_UNFOCUSED_ONHOVER_BORDER_COLOR}`,
            }
        }),

        singleValue: (provided: any, state: any) => {
            return {
                ...provided,
                color: 'white',
                fontSize: 18,
                fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif"
            };
        },
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected
                ? cColors.TASK_TYPE_SELECT_WEB_ITEM_SELECTED_BG_COLOR
                : state.isFocused
                    ? cColors.TASK_TYPE_SELECT_WEB_ITEM_UNSELECTED_FOCUSED_BG_COLOR
                    : cColors.TASK_TYPE_SELECT_WEB_ITEM_UNSELECTED_UNFOCUSED_BG_COLOR,
            "&:active": {
                backgroundColor: cColors.TASK_TYPE_SELECT_WEB_ITEM_FOCUSED_BG_COLOR,
            },
            paddingTop: 12,
            paddingBottom: 12,
            color: state.isSelected ? 'black' : state.isFocused ? 'black' : 'white',
            paddingLeft: 12 + 4,
            fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif"
        }),
        indicatorSeparator: () => ({ borderColor: 'none' })
    } as StylesConfig<{ label: string, value: string }, boolean, any>
    return <Select
        styles={customStyles}
        options={options}
        defaultValue={{ label: "Just today", value: "JUST_TODAY" }}
        onChange={(opt) => {
            onValueChange(opt)
        }}
        isSearchable={false}
        value={selected}
    />;
};

export default (() => Platform.select({
    ios: MobileTypeSelect,
    android: MobileTypeSelect,
    web: WebTypeSelect,
    macos: MobileTypeSelect,
    windows: MobileTypeSelect,
}))();

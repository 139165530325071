import { normalizeActiveTasks, seperateTasksByType } from "../utils/tasks"
import { HOST } from "../api"
import { publicStore } from '../redux/public'
import { getPublicSaveForLaterTasksSuccessAction, getPublicForTodayTasksSuccessAction } from '../redux/tasks/publicTasksActions'

export async function loadPublicTasks(contextId: string) {
  const tasks = await fetch(`${HOST}/api/public/tasks/?context_id=${contextId}`, {
  }).then(res => res.json())
  const [saveForLater, forToday,] = seperateTasksByType(tasks)
  publicStore.dispatch(getPublicSaveForLaterTasksSuccessAction(normalizeActiveTasks(saveForLater)))
  publicStore.dispatch(getPublicForTodayTasksSuccessAction(normalizeActiveTasks(forToday)))
  // console.log(tasks)
}

import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { SafeAreaView, View, Text, Platform, Image } from "react-native"
import { HOST } from "../api"
import cColors from "../styles/componentColors"
import { store } from "../redux"
import { startUserLoginAction, successfulLoginAction } from "../redux/user/userActions"
import { errorToast, warningToast } from "../utils/toast"
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { toSnakeCaseKeys } from '../utils/objects'
import RoundedButton from "../components/RoundedButton"
import { SignUpForm, signUpUser } from "../services/userService"
import { ScrollView } from "react-native-gesture-handler"
import { delay } from "../utils/delay"
import Logger from "../services/external/logger"
import TextInput from "../components/TextInput"
import env from '../utils/env';
import AppleLoginButton from '../components/AppleLoginButton'
import CustomButton from '../components/Button'
import { useNavigation } from "@react-navigation/native"
import { styles, inputStyle, width } from '../styles/signUpInputStyles'
import { emailRegex, passwordRegex } from "../utils/validation"

WebBrowser.maybeCompleteAuthSession();

const expoClientId = env.GOOGLE_EXPO_WEB_CLIENT_ID
const iosClientId = env.GOOGLE_IOS_CLIENT_ID
const androidClientId = env.GOOGLE_ANDROID_CLIENT_ID
const webClientId = env.GOOGLE_EXPO_WEB_CLIENT_ID
const logger = new Logger(env.LOGGER_TOKEN)

const PASSWORD_CRITERIA = `- At least one uppercase letter
- At least one lowercase letter
- At least one digit
- At least one special character from the set [!@#$%^&*-]
- Allowed characters are letters (both uppercase and lowercase), digits, and the special characters [!@#$%^&*-]
- Minimum length of 8 characters
`

const SignInScreen = ({ }: {}) => {
    const navigation = useNavigation()

    const [scrollEnabled, setScrollEnabled] = useState(false)
    const usingTauri = useRef(false)

    const scrollRef = useRef() as React.MutableRefObject<any>;
    const signInRef = useRef() as React.MutableRefObject<any>;
    const signUpRef = useRef() as React.MutableRefObject<any>;
    //@ts-ignore
    const isTauri: boolean = !!window.__TAURI__;

    const [request, response, promptAsync] = isTauri
        ? [null, null, () => {
            logger.log("GOOGLE_SIGN_IN", "empty sign in")
        }]
        : Google.useAuthRequest({
            expoClientId,
            iosClientId,
            androidClientId,
            webClientId,
        })


    const afterLoginSuccess = async (token: string) => {
        (async () => {
            store.dispatch(successfulLoginAction(token))
            afterClick()
        })()
            .then(() => {
                delay(120)
                    .then(() => {
                        // @ts-ignore
                        navigation.navigate('Home', { fromPage: 'signup' })
                    })
                    .catch(e => {

                    })
            }).catch(e => {
            })
    }

    const scrollToEnd = () => {
        scrollRef.current.scrollToEnd()
    }

    const scrollToBegining = () => {
        scrollRef.current.scrollTo({ y: 0 })
    }

    const afterClick = () => {
        signInRef.current && signInRef.current.clearText()
        signUpRef.current && signUpRef.current.clearText()
    }

    useEffect(() => {
        //@ts-ignore
        if (window.__TAURI__) {
            usingTauri.current = true
        }
        if (response?.type === 'success') {
            // @ts-ignore
            const { authentication } = response;

            const body = toSnakeCaseKeys({ ...authentication, expiresIn: (typeof authentication?.expiresIn === 'string') ? Number(authentication?.expiresIn) : authentication?.expiresIn })

            fetch(`${HOST}/auth/continue/google`, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(resp => resp.json())
                .then((resp: { token: string, response: string }) => {
                    const { token, response } = resp;
                    if (response === "Success") {
                        (async () => {
                            store.dispatch(successfulLoginAction(resp.token))

                        })()
                            .then(() => {
                                delay(120)
                                    .then(() => {
                                        // @ts-ignore
                                        navigation.navigate('Home', { fromPage: 'signup' })
                                    })
                                    .catch(e => {

                                    })
                            }).catch(e => {
                            })
                    }

                })
                .catch(e => {
                    errorToast(e)
                })

        } else {
            if (response) {
                errorToast(JSON.stringify(response ?? ""))
            }
        }
        if (scrollRef.current) {
            if (Platform.OS === 'web') {
                // console.log(scrollRef.current)
                scrollRef.current.children[0].children[0].style.width = "100%"
                scrollRef.current.children[0].children[1].style.width = "100%"
            }
        }
    }, [response, scrollRef]);

    return <SafeAreaView style={Platform.OS === 'android' || Platform.OS === 'ios' ? styles.container : styles.webContainer} >
        <ScrollView
            ref={scrollRef}
            decelerationRate="fast"
            style={Platform.OS === 'android' || Platform.OS === 'ios' ? styles.containerMobile : styles.webScroll}
            horizontal
            pagingEnabled
            scrollEnabled={scrollEnabled}
            onMomentumScrollEnd={() => {
                setScrollEnabled(false)
            }}
            contentContainerStyle={Platform.OS === 'android' || Platform.OS === 'ios' ? {} : { flex: 1 }}
            showsHorizontalScrollIndicator={false}
        >
            {/* Sign In Page  */}
            <SignInPage
                ref={signInRef}
                navigation={navigation}
                scrollToEnd={scrollToEnd}
                afterClick={afterClick}
                setScrollEnabled={setScrollEnabled}
            />

            {/* Sign Up Page  */}
            <SignUpPage
                ref={signUpRef}
                navigation={navigation}
                scrollToBegining={scrollToBegining}
                afterClick={afterClick}
                setScrollEnabled={setScrollEnabled}
            />
        </ScrollView>

        {!isTauri && <View
            style={{
                paddingTop: 8,
                paddingBottom: 12,
                width: Platform.OS === 'android' || Platform.OS === 'ios' ? width : '420px'
            }}>
            <Text style={styles.subLabel}>{"You can also"} </Text>
            {true && <View style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 10 }}>
                <AppleLoginButton afterLoginSuccess={afterLoginSuccess} />
            </View>}
            <RoundedButton
                singleTap={() => {
                    logger.log("GOOGLE_SIGN_IN", "tap google")
                    promptAsync();
                }}
            >
                <View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 8 }}>
                    <Image
                        source={require('../assets/third-party-assets/google/google_icon_sign_in.png')} // Adjust the path and file name accordingly
                        style={{ width: 24, height: 24 }} // Adjust the size as needed
                    />
                    <Text style={styles.continueButtonText}>Continue with Google</Text>
                </View>
            </RoundedButton>
        </View>
        }
    </SafeAreaView>
}

interface PageProps {
    afterClick: () => void,
    navigation: any,
    scrollToEnd?: () => void,
    scrollToBegining?: () => void,
    setScrollEnabled: (enabled: boolean) => void
}

function SignInPageForwarded({ afterClick, navigation, scrollToEnd, setScrollEnabled }: PageProps, ref: any) {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [loginIsValid, setLoginIsValid] = useState(false)

    useImperativeHandle(ref, () => ({
        clearText() {
            setEmail("")
            setPassword("")
            setLoginIsValid(false)
            setError("")
        }
    }));

    const validateLoginInputs = (emailText: string, passwordText: string) => {
        if (emailText.length === 0 || passwordText.length === 0) {
            setLoginIsValid(false);
        } else {
            setLoginIsValid(true);
        }
    }

    const validateLoginInputsOnBlur = () => {
        validateLoginInputs(email, password)
    }

    const login = () => {

        if (!loginIsValid) {
            return
        }

        store.dispatch(startUserLoginAction(email))

        fetch(`${HOST}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ username: email, password }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(resp => resp.json())
            .then((resp) => {
                if (resp.response === "Success") {
                    (async () => {
                        store.dispatch(successfulLoginAction(resp.token))
                        afterClick()
                    })()
                        .then(() => {
                            delay(120)
                                .then(() => {
                                    navigation.navigate('Home', { fromPage: "login" })
                                })
                                .catch(e => {

                                })
                        }).catch(e => {

                        })
                } else {
                    warningToast("Login failed", 'bottom')
                }
            })
            .catch(e => {
                warningToast("Login failed", 'bottom')
            })

    }
    return <View
        style={styles.page}
    >
        <Text style={styles.label}>{"Log in"} </Text>
        <Text style={styles.subLabel}>{"with email"} </Text>

        <TextInput
            value={email}
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect={false}
            style={Platform.OS === 'web' ? inputStyle : styles.input}
            placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
            placeholder="Email"
            onChangeText={(text) => {
                setEmail(text)
                validateLoginInputs(text, password)
            }}
            numberOfLines={1}
            onBlur={validateLoginInputsOnBlur}
        />
        <TextInput
            numberOfLines={1}
            value={password}
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect={false}
            style={Platform.OS === 'web' ? inputStyle : styles.input}
            placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
            placeholder="Password"
            secureTextEntry={true}
            onChangeText={(text) => {
                setPassword(text)
                validateLoginInputs(email, text)
            }}
            onSubmitEditing={() => {
                login()
            }}
            onBlur={validateLoginInputsOnBlur}
        />

        {<View style={styles.padding} />}

        <RoundedButton
            singleTap={() => {
                login()
            }}
            disabled={!loginIsValid}
        >
            <Text style={styles.buttonText}>Login</Text>
        </RoundedButton>
        <View style={styles.padding} />
        <View
            style={styles.forgotPasswordContainer}>
            <CustomButton
                label="Forgot password?"
                type="link"
                href='https://web.minilist.app/reset-password'
                color={cColors.BOTTOM_BUTTON_COLOR}
            
            />
            {Platform.OS === 'android' || Platform.OS === 'ios' && <View style={styles.smallPadding} />}
        </View>

        {error.length > 0
            ? <Text style={styles.errorLabel}>{error}</Text>
            : <View style={{ paddingVertical: 22 }} />
        }
        <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={styles.subLabel}>{"Don't have an account?"} </Text>
            <View style={{ paddingBottom: 10 }}>
                <CustomButton
                    label="Sign Up here"
                    type="button"
                    onPress={() => {
                        setScrollEnabled(true)
                        delay(200).then(() => {
                            scrollToEnd && scrollToEnd()
                        })
                    }}
                    color={cColors.AUTH_BUTTON_COLOR}
                    fontSize={16}
                />
            </View>
        </View>
        {Platform.OS === 'web' && <View style={styles.padding} />}

    </View>
}

function SignUpPageForwarded({ afterClick, navigation, scrollToBegining, setScrollEnabled }: PageProps, ref: any) {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [newEmail, setNewEmail] = useState("")
    const [newUsername, setNewUsername] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [error, setError] = useState("")
    const [didTypeOnce, setDidTypeOnce] = useState(false)
    const [showPwReqs, setShowPwReqs] = useState(false)

    useImperativeHandle(ref, () => ({
        clearText() {
            setFirstName("")
            setLastName("")
            setNewUsername("")
            setNewEmail("")
            setNewPassword("")
            setError("")
        }
    }));

    const validateSignUpInputs = (
        firstNameText: string,
        lastNameText: string,
        newUsernameText: string,
        newEmailText: string,
        newPasswordText: string
    ) => {
        setDidTypeOnce(val => true)
        const emailIsValid = emailRegex.test(newEmailText)
        const passwordIsValid = passwordRegex.test(newPasswordText)
        const emptyPw = newPasswordText.length === 0
        const emptyEmail = newEmailText.length === 0

        if (!emailIsValid && passwordIsValid) {
            setError("Email is invalid")
        }
        if (emailIsValid && !passwordIsValid) {
            if (emptyPw) {
                setError("Password can't be empty")
            } else {
                setError("Password is invalid")
            }
        }
        if (!emailIsValid && !passwordIsValid) {
            setError("Email and password invalid")
        }
        if (emailIsValid && passwordIsValid) {
            setError("")
        }
    };

    const validateSignUpInputsOnBlur = () => {
        setShowPwReqs(false)
        validateSignUpInputs(firstName, lastName, newUsername, newEmail, newPassword)
    }

    const signUp = () => {
        if (error === '') {
            signUpUser({
                firstName,
                lastName,
                username: newUsername,
                email: newEmail,
                password: newPassword
            } as SignUpForm)
                .then((resp) => {
                    if (resp.response === "Success") {
                        (async () => {
                            afterClick()
                            store.dispatch(successfulLoginAction(resp.token))
                            store.dispatch(startUserLoginAction(newUsername))
                        })().then(() => {
                            delay(120)
                                .then(() => {
                                    navigation.navigate('Home', { fromLogin: true })
                                }).catch(e => {
                                })
                        }).catch(e => {
                        })
                    } else {
                        warningToast("Sign Up failed", 'bottom')
                    }
                }).catch(e => {
                    warningToast("Sign Up failed", 'bottom')
                })
        }
    }

    return <View
        style={styles.page}>
        <Text style={styles.label}>{"Sign Up"} </Text>
        <Text style={styles.subLabel}>{"with email"} </Text>

        <TextInput
            value={firstName}
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect={false}
            style={styles.input}
            placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
            placeholder="First Name"
            onChangeText={(text) => {
                setFirstName(text)
                validateSignUpInputs(text, lastName, newUsername, newEmail, newPassword)
            }}
            onBlur={validateSignUpInputsOnBlur} />

        <TextInput
            value={lastName}
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect={false}
            style={styles.input}
            placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
            placeholder="Last Name"
            secureTextEntry={false}
            onChangeText={(text) => {
                setLastName(text)
                validateSignUpInputs(firstName, text, newUsername, newEmail, newPassword)

            }}
            onBlur={validateSignUpInputsOnBlur} />
        <TextInput
            value={newUsername}
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect={false}
            style={styles.input}
            placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
            placeholder="Username"
            secureTextEntry={false}
            onChangeText={(text) => {
                setNewUsername(text)
                validateSignUpInputs(firstName, lastName, text, newEmail, newPassword)

            }}
            onBlur={validateSignUpInputsOnBlur} />
        <TextInput
            value={newEmail}
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect={false}
            style={styles.input}
            placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
            placeholder="Email"
            onChangeText={(text) => {
                setNewEmail(text)
                validateSignUpInputs(firstName, lastName, newUsername, text, newPassword)
            }}
            onBlur={validateSignUpInputsOnBlur} />
        <View style={{}}>
            <TextInput
                value={newPassword}
                autoComplete="off"
                autoCapitalize="none"
                autoCorrect={false}
                style={styles.input}
                placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
                placeholder="Password"
                secureTextEntry={true}
                onChangeText={(text) => {
                    setNewPassword(text)
                    validateSignUpInputs(firstName, lastName, newUsername, newEmail, text)
                }}
                onBlur={validateSignUpInputsOnBlur}
                onFocus={() => {
                    setShowPwReqs(true)
                }}
            />

            {showPwReqs && <View style={{
                position: 'absolute',
                bottom: 60,
                backgroundColor: cColors.PW_REQ_CONTAINER_COLOR,
                paddingHorizontal: 12,
                paddingTop: 8,
                paddingBottom: 4
            }}>
                <Text style={{
                    color: cColors.PW_REQUIREMENT_LABEL_COLOR,
                    fontSize: 15,
                    paddingBottom: 2
                }}>{"Password requirements"}</Text>
                <Text style={{
                    color: cColors.PW_REQUIREMENT_LABEL_COLOR,
                }}>{PASSWORD_CRITERIA}</Text>
            </View>}
        </View>


        <View style={styles.padding} />



        <RoundedButton
            singleTap={() => {
                signUp()
            }}
            disabled={error !== '' || !didTypeOnce}
        >
            <Text style={styles.buttonText}>Sign Up</Text>
        </RoundedButton>

        {error.length > 0 && false
            ? <Text style={styles.errorLabel}>{error}</Text>
            : <View style={{ paddingVertical: 22 }} />}

        <View style={styles.padding} />
        {Platform.OS === 'android' || Platform.OS === 'ios' && <View style={styles.padding} />}
        <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={styles.subLabel}>{"Already have an account?"} </Text>
            <View style={{ paddingBottom: 10 }}>
                <CustomButton
                    label="Login here"
                    type="button"
                    onPress={() => {
                        setScrollEnabled(true)
                        delay(200).then(() => {
                            scrollToBegining && scrollToBegining()
                        })
                    }}
                    color={cColors.AUTH_BUTTON_COLOR}
                    fontSize={16}
                />
            </View>
        </View>
        {/* <View style={styles.padding} /> */}
        {/* <View style={styles.padding} /> */}
        {/* <View style={styles.padding} /> */}

    </View>
}

const SignUpPage = forwardRef(SignUpPageForwarded)
const SignInPage = forwardRef(SignInPageForwarded)

export default SignInScreen

import chroma from "chroma-js"
import colors from "../colors"

const {
    MIDNIGHT_BLUE_MINUS_1,
    GAINSBORO,
    MIDDLE_BLUE_GREEN,
    DARK_ELECTRICS_BLUE,
    GAINSBORO_MINUS_2,
    DELETE_RED,
    LIGHT_PERIWINKLE_PLUS_3,
    LIGHT_PERIWINKLE_PLUS_2,
    LIGHT_PERIWINKLE_PLUS_1,
    LIGHT_PERIWINKLE,
    LIGHT_PERIWINKLE_MINUS_1,
    CHARCOAL,
    CHARCOAL_BUT_DARKER,
} = colors

type ColorsMapped = { [key: string]: string }

export default {
    "NAV_HEADER_BG_COLOR": MIDNIGHT_BLUE_MINUS_1,
    "NAV_HEADER_TEXT_COLOR": LIGHT_PERIWINKLE,

    "TOP_BAR_BG_COLOR": MIDNIGHT_BLUE_MINUS_1,
    "TOP_BAR_LABEL_TEXT_COLOR": GAINSBORO,
    "TOP_BAR_ICON_COLOR": GAINSBORO,
    "HOME_CONTAINER_BG_COLOR": "#000000",
    "TOP_BAR_DATE_TEXT_COLOR": GAINSBORO,

    "BOTTOM_BUTTON_COLOR": MIDDLE_BLUE_GREEN,
    "AUTH_BUTTON_COLOR": GAINSBORO,
    "BOTTOM_BUTTON_SHADOW_COLOR": MIDDLE_BLUE_GREEN,
    "BUTTON_TEXT_COLOR": CHARCOAL,

    "TASK_ITEM_BG_COLOR": "#000000",
    "TASK_ITEM_BORDER_COLOR": DARK_ELECTRICS_BLUE,
    "TASK_SFL_ITEM_FOCUS_BORDER_COLOR": GAINSBORO_MINUS_2,
    "TASK_DAILY_ITEM_BG_COLOR": MIDNIGHT_BLUE_MINUS_1,
    "TASK_DAILY_ITEM_BORDER_COLOR": DARK_ELECTRICS_BLUE,
    "TASK_ITEM_TEXT_COLOR": GAINSBORO,
    "TASK_ITEM_CHECKED_TEXT": GAINSBORO_MINUS_2,
    "TASK_ITEM_CHECKBOX_FILL_COLOR": DARK_ELECTRICS_BLUE,
    "SFL_ICON_COLOR": GAINSBORO,
    "DELETE_BUTTON_BG_COLOR": DELETE_RED,
    "DELETE_BUTTON_TEXT_COLOR": DELETE_RED,

    "DEFAULT_CONTEXT_BUBBLE_BG_COLOR": LIGHT_PERIWINKLE_MINUS_1,
    "DEFAULT_CONTEXT_BUBBLE_TEXT_COLOR": LIGHT_PERIWINKLE_MINUS_1,
    "DEFAULT_CONTEXT_BUBBLE_TEXT_COLOR_DARK": MIDNIGHT_BLUE_MINUS_1,
    "CONTEXT_BUBBLE_SELECTED_TEXT_COLOR": MIDNIGHT_BLUE_MINUS_1,
    "CONTEXT_BUBBLE_SELECTED_BG_COLOR": chroma(LIGHT_PERIWINKLE_MINUS_1).darken().hex(),
    "CONTEXT_BUBBLE_DELETE_ICON_COLOR": DELETE_RED,
    "CONTEXT_BUBBLE_DRAG_TO_SORT_ICON_COLOR": GAINSBORO,

    "HEADER_TEXT_COLOR": LIGHT_PERIWINKLE_MINUS_1,
    "PAGE_BG_COLOR": MIDNIGHT_BLUE_MINUS_1,
    "LABEL_TEXT_COLOR": LIGHT_PERIWINKLE_MINUS_1,

    "INPUT_PLACEHOLDER_TEXT_COLOR": LIGHT_PERIWINKLE_PLUS_2,
    "BOTTOM_BORDER_COLOR": CHARCOAL,

    "SWITCH_THUMB_COLOR_ENABLED": LIGHT_PERIWINKLE_MINUS_1,
    "SWITCH_THUMB_COLOR_DISABLED": "#f4f3f4",
    "SWITCH_IOS_BG_COLOR": "#3e3e3e",

    "PUBLIC_CONTEXT_LABEL_TEXT_COLOR": GAINSBORO,

    "AUTH_LABEL_TEXT_COLOR": LIGHT_PERIWINKLE_PLUS_1,
    "AUTH_SUBLABEL_TEXT_COLOR": LIGHT_PERIWINKLE_PLUS_1,

    "MODAL_TITLE_TEXT_COLOR": LIGHT_PERIWINKLE,
    "PW_REQUIREMENT_LABEL_COLOR": GAINSBORO_MINUS_2,

    "SETTING_ITEM_BORDER_COLOR": GAINSBORO_MINUS_2,
    "SETTING_ITEM_TEXT_COLOR": GAINSBORO,

    "PW_REQ_CONTAINER_COLOR": CHARCOAL_BUT_DARKER,
    "PLUS_ICON_COLOR": CHARCOAL,

    // ContextMenu Colors
    "CONTEXT_MENU_BG_COLOR": CHARCOAL_BUT_DARKER,
    "CONTEXT_MENU_BORDER_COLOR": CHARCOAL_BUT_DARKER,
    "CONTEXT_MENU_ITEM_BG_COLOR": CHARCOAL_BUT_DARKER,
    "CONTEXT_MENU_ITEM_TEXT_COLOR": GAINSBORO,
    "CONTEXT_MENU_TITLE_TEXT_COLOR": GAINSBORO_MINUS_2,

    "CONTEXT_MENU_ITEM_HOVER_BG_COLOR": GAINSBORO,
    "CONTEXT_MENU_ITEM_HOVER_BORDER_COLOR": GAINSBORO_MINUS_2,
    
    "CONTEXT_MENU_SEPERATOR_COLOR": CHARCOAL,

    "DRAG_TO_SORT_TEXT_COLOR_IOS": GAINSBORO_MINUS_2,
    "DRAG_TO_SORT_TEXT_COLOR_NOT_IOS": MIDNIGHT_BLUE_MINUS_1,

    "MODAL_PAGE_COLOR": MIDNIGHT_BLUE_MINUS_1,
    "MODAL_BUTTON_TEXT_COLOR_IOS": GAINSBORO_MINUS_2,
    "MODAL_BUTTON_TEXT_COLOR_NOT_IOS": MIDNIGHT_BLUE_MINUS_1,

    "ROUNDED_BUTTON_DISABLED_COLOR": GAINSBORO_MINUS_2,
    "ROUNDED_BUTTON_SOLID_COLOR": 'white',
    "ROUNDED_BUTTON_SOLID_BORDER_COLOR": 'white',
    "ROUNDED_BUTTON_OUTLINE_BORDER_COLOR": 'white',

    "SEARCH_INPUT_CLICKED_TEXT_COLOR": GAINSBORO,
    "SEARCH_INPUT_UNCLICKED_TEXT_COLOR": GAINSBORO_MINUS_2,
    "SEARCH_INPUT_PLACEHOLDER_TEXT_COLOR": GAINSBORO_MINUS_2,
    "SEARCH_ICON_CLICKED_COLOR": GAINSBORO,
    "SEARCH_ICON_UNCLICKED_COLOR": GAINSBORO_MINUS_2,
    "SEARCH_X_ICON_COLOR": GAINSBORO,
    "SEARCH_CANCEL_BUTTON_TEXT_COLOR": 'white',

    "TASK_TYPE_SELECT_ITEM_MOBILE_COLOR_IOS": 'white',
    "TASK_TYPE_SELECT_ITEM_MOBILE_COLOR_NOT_IOS": MIDNIGHT_BLUE_MINUS_1,
    "TASK_TYPE_SELECT_WEB_BG_COLOR": CHARCOAL,

    "TASK_TYPE_SELECT_WEB_CONTROL_FOCUSED_BORDER_COLOR": LIGHT_PERIWINKLE,
    "TASK_TYPE_SELECT_WEB_CONTROL_UNFOCUSED_BORDER_COLOR": LIGHT_PERIWINKLE_MINUS_1,

    "TASK_TYPE_SELECT_WEB_CONTROL_FOCUSED_ONHOVER_BORDER_COLOR": LIGHT_PERIWINKLE_PLUS_3,
    "TASK_TYPE_SELECT_WEB_CONTROL_UNFOCUSED_ONHOVER_BORDER_COLOR": LIGHT_PERIWINKLE_MINUS_1,

    "TASK_TYPE_SELECT_WEB_ITEM_SELECTED_BG_COLOR": LIGHT_PERIWINKLE_PLUS_3,

    "TASK_TYPE_SELECT_WEB_ITEM_UNSELECTED_FOCUSED_BG_COLOR": LIGHT_PERIWINKLE,
    "TASK_TYPE_SELECT_WEB_ITEM_UNSELECTED_UNFOCUSED_BG_COLOR": 'transparent',
    "TASK_TYPE_SELECT_WEB_ITEM_FOCUSED_BG_COLOR": LIGHT_PERIWINKLE,
} satisfies ColorsMapped
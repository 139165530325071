import React from 'react'
// @ts-ignore
import DoubleClick from 'react-native-double-tap'
import { TouchableWithoutFeedback } from "react-native-gesture-handler"

export const TouchableComponent  = (props: {disabled?: boolean, children?: any, doubleTap?: () => void, singleTap?: () => void, delay?: number }) => {
  const children = React.Children.only(props.children);

  return props.disabled ?
      <TouchableWithoutFeedback disabled={true}>{props.children}</TouchableWithoutFeedback>
      : <DoubleClick
         singleTap={props.singleTap}
         doubleTap={props.doubleTap}
         delay={props.delay || 250}
       >{children}</DoubleClick>
}

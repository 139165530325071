import { combineReducers, createStore } from "redux";
import { contextReducer } from "./contexts/contextsReducer";
import { TasksActions } from "./tasks/tasksActions";
import { allTasksReducer } from "./tasks/tasksReducer";
import { userReducer } from "./user/userReducer";
import { visualReducer } from "./visual/visualReducer";
import { contextWidthsReducer } from "./contextWidths/contextWidthsReducer";

const mainReducer = combineReducers({
  visual: visualReducer,
  allTasks: allTasksReducer,
  user: userReducer,
  contexts: contextReducer,
  contextWidths: contextWidthsReducer
})

export type RootState = ReturnType<typeof mainReducer>;

export const store = createStore(mainReducer)

import * as React from 'react';
import { View, Text, StyleSheet, Pressable, Platform, LayoutChangeEvent, } from "react-native"
import { FlatList } from "react-native-gesture-handler"
import { connect } from 'react-redux';
import { Context, MappedContexts } from '../types/Context';
import cColors from "../styles/componentColors"
import chroma from 'chroma-js'
import { setContextWidthAction } from '../redux/contextWidths/contextWidthsActions';
import { MappedContextWidths } from '../redux/contextWidths/contextWidthsReducer';

const SIDE_MARGIN_VALUE = 10;

interface ContextBubbleProps {
    id: string | null,
    contextIndex: string | null,
    setContextIndex: (id: string | null) => void,
    hideBuiltInFilters?: boolean,
    context?: Context | { name: string, id: string },
    onPress?: () => void,
    marginSide: 'left' | 'right',
    isCache?: boolean,
    setContextWidth: (contextId: string, width: number | string) => void
}

export const ContextBubble = ({ id, contextIndex, setContextIndex, context: contextObj, onPress, marginSide, hideBuiltInFilters, isCache = false, setContextWidth }: ContextBubbleProps) => {
    const ref = React.useRef<any>()
    const builtInFunctions: ContextOrButtonMap = {
        all: { id: 'all', name: "All Tasks" },
        contextless: { id: 'contextless', name: "Without Context" },
        addTaskButton: { id: 'addTaskButton', name: "+ Create Context", isBuiltIn: true }
    }
    const context = contextObj ? contextObj : builtInFunctions[id as string]
    const contextColor: string = ((context as Context).color ? (context as Context).color : cColors.DEFAULT_CONTEXT_BUBBLE_BG_COLOR) as string;
    const contrast = chroma.contrast(cColors.DEFAULT_CONTEXT_BUBBLE_TEXT_COLOR_DARK, contextColor) < 4.5
    const textColor = contrast ? 'white' : 'black';
    const outlineColor = !contrast ? chroma(contextColor).darken() : chroma(contextColor).brighten()

    return <View
        ref={ref}
        onLayout={isCache
            ? ({ nativeEvent }) => {
                const { x, y, width, height } = nativeEvent.layout
                setContextWidth(id as string, width)
            }
            : undefined}
        style={{ marginLeft: marginSide === 'left' ? SIDE_MARGIN_VALUE : 0, marginRight: marginSide === 'right' ? SIDE_MARGIN_VALUE : 0 }}>
        <Pressable
            key={id}
            onPress={() => {
                if (id === 'addTaskButton') {
                    if (onPress) { onPress() }
                }
                if (contextIndex === id) {
                    if (!hideBuiltInFilters) {
                        setContextIndex('all')
                    } else {
                        setContextIndex(null)
                    }
                } else {
                    setContextIndex(id)
                }
            }}
        >
            <View style={id === 'addTaskButton' ? { borderWidth: 0, borderRadius: 0, paddingHorizontal: 6, paddingVertical: 12, marginRight: 10 } : (contextIndex === id ? { ...categorySelected, backgroundColor: contextColor, borderColor: outlineColor.hex() } : { ...category, })}><Text style={contextIndex === id ? { ...categoryNameSelected, color: textColor } : styles.categoryName}>{context?.name}</Text>
            </View>
        </Pressable>
    </View>
}

type ContextOrButton = Context | { id: string, name: string, isBuiltIn?: boolean }
interface ContextOrButtonMap {
    [key: string]: ContextOrButton
}

interface ContextBubblesTopListProps {
    setContextIndex: (id: string | null) => void,
    contextIndex: string | null,
    contexts: MappedContexts,
    navigation: any,
    hideBuiltInFilters?: boolean,
    isSearchResults?: boolean,
    marginSide: "left" | "right",
    isCache?: boolean,
    setContextWidth: (contextId: string, width: number | string) => void,
    contextWidths: MappedContextWidths,
}

const ContextBubblesTopList = ({ setContextIndex, contextIndex, contexts, navigation, hideBuiltInFilters, isSearchResults, marginSide = "right", isCache = false, setContextWidth, contextWidths }: ContextBubblesTopListProps) => {
    const ids = Object.keys(contexts);
    let contextsArr: Context[] = ids.map(cId => contexts[cId])
    let hasPosition: Context[] = []
    let notHasPosition: Context[] = []

    for (let i = 0; i < contextsArr.length; i++) {
        if (typeof contextsArr[i].position !== 'undefined') {
            hasPosition.push(contextsArr[i])
        } else {
            notHasPosition.push(contextsArr[i])
        }
    }

    const contextsListIds = hasPosition.sort((a, b) => (a.position as number) - (b.position as number)).map(c => c.id)
    const contextIds: string[] = hideBuiltInFilters ? contextsListIds : isSearchResults ? ['all', 'contextless', ...contextsListIds, 'addTaskButton'] : ['all', 'contextless', ...contextsListIds, 'addTaskButton']
    const index = contextIds.indexOf(contextIndex as string)

    const contextOffsets: number[] = [marginSide === 'left' ? SIDE_MARGIN_VALUE : 0]
    for (let i = 1; i < contextIds.length; i++) {
        let sum = 0;
        for (let j = 0; j < i; j++) {
            if (marginSide === 'left') {
                sum += contextWidths[contextIds[j]] as number
            } else {
                sum += (contextWidths[contextIds[j]] as number)
            }
        }
        contextOffsets.push(sum)
    }

    return <View style={{
        height: Object.keys(contexts).length === 0 ? 0 : 50,
        marginBottom: 20,
    }} >
        <FlatList
            horizontal
            data={contextIds}
            initialScrollIndex={!isCache ? index : undefined}
            getItemLayout={
                !isCache
                    ? (data, index) => ({
                        length: contextWidths[contextIds[index]] as number,
                        offset: contextOffsets[index],
                        index: index
                    })
                    : undefined}
            extraData={contexts}
            showsHorizontalScrollIndicator={false}
            renderItem={(info) => <ContextBubble key={info.item} id={info.item} contextIndex={contextIndex} setContextIndex={setContextIndex} context={contexts[info.item]} hideBuiltInFilters={hideBuiltInFilters} setContextWidth={setContextWidth} isCache={isCache}
                marginSide={marginSide}
                onPress={() => {
                    navigation.navigate('CreateContext')
                }} />}
        />
    </View>
}

const mapStateToProps = function (state: any) {
    return {
        contexts: state.contexts,
        contextWidths: state.contextWidths
    }
}
const mapDispatchToProps = function (dispatch: (action: any) => void) {
    return {
        setContextWidth(contextId: string, width: number | string) {
            dispatch(setContextWidthAction(contextId, width))
        }
    }
}

const category = {
    borderRadius: 9999,
    paddingHorizontal: 14,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: cColors.DEFAULT_CONTEXT_BUBBLE_TEXT_COLOR,
}

const categorySelected = {
    borderRadius: 9999,
    paddingHorizontal: 14,
    paddingVertical: 12,
    borderWidth: 1,
    backgroundColor: cColors.CONTEXT_BUBBLE_SELECTED_BG_COLOR
}

const categoryNameSelected = {
    color: cColors.CONTEXT_BUBBLE_SELECTED_TEXT_COLOR,
    fontSize: 18
}

const styles = StyleSheet.create({
    categorySelected: categorySelected,
    category: category,
    categoryContainer: {
        flexDirection: 'row',
        marginBottom: 10,
        height: 70,
        flex: 1,
        display: 'flex',
    },
    categoryName: {
        color: 'white',
        fontSize: 18
    },
    categoryNameSelected,
    contextScrollStyle: {
        height: 400
    },

})

export default connect(mapStateToProps, mapDispatchToProps)(ContextBubblesTopList)

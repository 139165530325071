import Toast from 'react-native-root-toast';

export const errorToast = (e: any) => {
    let toast = Toast.show(`FetchErr ${e}`, {
        duration: Toast.durations.LONG,
        position: Toast.positions.BOTTOM,
        shadow: true,
        animation: true,
        hideOnPress: true,
        delay: 0,
        onShow: () => {
            // calls on toast\`s appear animation start
        },
        onShown: () => {
            // calls on toast\`s appear animation end.
        },
        onHide: () => {
            // calls on toast\`s hide animation start.
        },
        onHidden: () => {
            // calls on toast\`s hide animation end.
        }
    });
}

export const warningToast = (e: any, position: string, bottom: number = 100) => {
    let toast = Toast.show(`${e}`, {
        containerStyle: {
            bottom
        },
        duration: Toast.durations.LONG,
        position: position === 'center' ? Toast.positions.CENTER : Toast.positions.BOTTOM,
        shadow: true,
        animation: true,
        hideOnPress: true,
        delay: 0,
        onShow: () => {
            // calls on toast\`s appear animation start
        },
        onShown: () => {
            // calls on toast\`s appear animation end.
        },
        onHide: () => {
            // calls on toast\`s hide animation start.
        },
        onHidden: () => {
            // calls on toast\`s hide animation end.
        }
    });
}

import { normalizeContexts } from "../utils/context"
import { HOST } from "../api"
import { publicStore } from '../redux/public'
import { getPublicContextSuccessAction } from '../redux/contexts/publicContextActions'

export async function loadPublicContext(contextId: string) {
  const context = await fetch(`${HOST}/api/public/context/${contextId}`, {
  }).then(res => res.json())
  publicStore.dispatch(getPublicContextSuccessAction(normalizeContexts([context])))
}

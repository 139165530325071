import React from "react";
import { StyleSheet, View, Keyboard, Platform } from "react-native";
import { Feather, Entypo } from "@expo/vector-icons";
import cColors from "../styles/componentColors";
import TextInput from "./TextInput";
import Button from '../components/Button'

const input__clicked = {
    fontSize: 20,
    marginLeft: 0,
    width: Platform.OS === 'web' ? 'calc(100% - 40px)' : "80%",
    color: cColors.SEARCH_INPUT_CLICKED_TEXT_COLOR,
    border: 'none',
    paddingLeft: 0
}
const input__unclicked = {
    fontSize: 20,
    marginLeft: 0,
    width: "100%",
    color: cColors.SEARCH_INPUT_UNCLICKED_TEXT_COLOR,
    border: 'none',
    paddingLeft: 0
}

const SearchBar = ({ clicked, searchPhrase, setSearchPhrase, setClicked, onCancel }: { clicked: boolean, searchPhrase: string, setSearchPhrase: (text: string) => void, setClicked: (clicked: boolean) => void, onCancel: () => void }) => {
    return (
        <View style={styles.container}>
            <View
                style={
                    clicked
                        ? styles.searchBar__clicked
                        : styles.searchBar__unclicked
                }
            >
                {/* search Icon */}
                {/* @ts-ignore */}
                <Feather
                    name="search"
                    size={20}
                    color={
                        clicked
                            ? cColors.SEARCH_ICON_CLICKED_COLOR
                            : cColors.SEARCH_ICON_UNCLICKED_COLOR
                    }
                    style={{ marginLeft: 8, marginRight: 5 }}
                />
                {/* Input field */}
                <TextInput
                    style={clicked ? (Platform.OS === 'web' ? input__clicked : styles.input__clicked) : (Platform.OS === 'web' ? input__unclicked : styles.input__unclicked)}
                    placeholder="Search"
                    value={searchPhrase}
                    onChangeText={setSearchPhrase}
                    onFocus={() => {
                        setClicked(true);
                    }}
                    placeholderTextColor={cColors.SEARCH_INPUT_PLACEHOLDER_TEXT_COLOR}
                    numberOfLines={1}
                />
                {/* cross Icon, depending on whether the search bar is clicked or not */}
                {clicked && (
                    // @ts-ignore
                    <Entypo name="cross" size={20} style={{ padding: 1 }} onPress={() => { setSearchPhrase("") }}
                        color={cColors.SEARCH_X_ICON_COLOR}
                    />
                )}
            </View>
            {/* cancel button, depending on whether the search bar is clicked or not */}
            {clicked && (
                <View style={{ paddingLeft: 17 }}>
                    <Button
                        label="Cancel"
                        type="button"
                        color={cColors.SEARCH_CANCEL_BUTTON_TEXT_COLOR}
                        fontSize={18}
                        onPress={() => {
                            Keyboard.dismiss();
                            setClicked(false);
                            setSearchPhrase("");
                            onCancel()
                        }}
                    ></Button>
                </View>
            )}
        </View>
    );
};
export default SearchBar;

// styles
const styles = StyleSheet.create({
    container: {
        // margin: 15,
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",

    },
    searchBar__unclicked: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 4,
        flexDirection: "row",
        width: "100%",
        borderRadius: 15,
        alignItems: "center",
        backgroundColor: "transparent",
    },
    searchBar__clicked: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 4,
        flexDirection: "row",
        width: Platform.OS === 'web' ? "calc(100% - 78px)" : "80%",
        backgroundColor: '#525F75',
        borderRadius: 15,
        alignItems: "center",
        // justifyContent: "space-evenly",
    },
    input__clicked,
    input__unclicked
});
import { useEffect, useState } from "react";
import { View, Text, Linking } from "react-native";
import tw from "twrnc";
import { connect } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import colors from "../colors";
import { MappedContexts } from "../types/Context";


function PublicListScreen(props: { navigation: any, contexts: MappedContexts }) {
  useEffect(() => {
    window.location.href = 'https://minilist.app';
  }, [])
  return <>
    <View style={tw.style(`w-full flex flex-col px-4 py-4`, { height: "100vh", backgroundColor: colors.CHARCOAL })}>
    </View>
  </>
}

const mapStateToProps = function (state: any,) {
  return {
    contexts: state.contexts,
  }
}

export default connect(mapStateToProps)(PublicListScreen)


import * as React from 'react';
import { View, Text, StyleSheet, Dimensions, Platform } from "react-native"
import cColors from "../styles/componentColors"
import BottomButton from '../components/BottomButton';
import ItemsFlatList from '../components/ItemsFlatList';
import { useEffect } from 'react';
import { delay } from '../utils/delay';
import { addTaskIdToRecentTasks, loadAllTasks, loadSaveForLaterTasks, loadSearchResultTasks, moveTasksToJustToday, resetSearchResultTask } from '../services/tasksService';
import ModalContainer from '../components/ModalContainer';
import { warningToast } from '../utils/toast';
import { ScrollView } from "react-native-gesture-handler"
import ContextsView from '../components/ContextsView';
import { loadAllContexts } from '../services/contextService';
import ContextBubblesTopList from '../components/ContextBubblesTopList';
import SearchBar from '../components/SearchBar';
import debounce from 'lodash.debounce';
import IconBottomButton from '../components/IconBottomButton';
import Svg, { Path } from "react-native-svg";


export default ({ navigation }: { navigation: any, }) => {

    const [selectTaskIds, setSelectedTaskIds] = React.useState<string[]>([]);
    const scrollRef = React.useRef() as React.MutableRefObject<any>;
    const [scrollEnabled, setScrollEnabled] = React.useState(false)
    const [clicked, setClicked] = React.useState(false)
    const [isSearchResults, setIsSearchResults] = React.useState(false)
    const [searchPhrase, setSearchPhrase] = React.useState<string>("");
    const [page, setPage] = React.useState<"list" | "contexts">("list")
    const [refreshing, setRefreshing] = React.useState(false);
    const [filterIndex, setFilterIndex] = React.useState<string>("contextless");
    const [shouldUseAllOnNextSearch, setShouldUseAllOnNextSearch] = React.useState<boolean>(true)
    const onKeyPress = () => {
        setRefreshing(false)
    }

    const onButtonPress = async () => {
        if (selectTaskIds.length > 0) {
            await moveTasksToJustToday(selectTaskIds)
            await delay(200)
            await loadAllTasks()
            await delay(200)
            navigation.goBack()
        } else {
            warningToast('Select some tasks to move', 'bottom')
        }
    }

    const onAddRecentButtonPress = async () => {
        if (selectTaskIds.length > 0) {
            await addTaskIdToRecentTasks(selectTaskIds)
            await delay(200)
            navigation.goBack()
        } else {
            warningToast('Select some tasks to move', 'bottom')
        }
    }

    const setTaskIds = (taskIds: string[]) => {
        setSelectedTaskIds(taskIds)
    }

    useEffect(() => {
        if (scrollRef.current) {
            if (Platform.OS === 'web') {
                scrollRef.current.children[0].children[0].style.width = "100%"
                scrollRef.current.children[0].children[1].style.width = "100%"
            }
        }

        const shortcutHandler = (event: any) => {
            if ((event.code === 'KeyB' && event.metaKey) || (event.ctrlKey && event.code === 'KeyB')) {
                navigation.goBack()
            }
        }

        //@ts-ignore
        if (window.__TAURI__) {
            document.body.addEventListener("keypress", shortcutHandler)
        }

        if (isSearchResults) {
            loadSearchResultTasks({ search_term: searchPhrase, context_id: filterIndex, task_type: "SAVE_FOR_LATER" })
        } else {
            loadSaveForLaterTasks();
            loadAllContexts()
        }


        return () => {
            //@ts-ignore
            if (window.__TAURI__) {
                document.body.removeEventListener('keypress', shortcutHandler)
            }
        }
    }, [isSearchResults, filterIndex])

    const handleSearchPhraseChange = (searchPhraseVal: string) => {
        // search term
        if (searchPhraseVal) {
            loadSearchResultTasks({ search_term: searchPhraseVal, context_id: filterIndex, task_type: "SAVE_FOR_LATER" })
        }

    }

    const debouncedHandleSearchPhraseChange = React.useMemo(
        () => debounce(handleSearchPhraseChange, 300)
        , [filterIndex]);


    return <ModalContainer
        navigation={navigation}
        enableRightButton
        rightButtonText={page === 'list' ? 'Contexts' : 'List'}
        rightButtonOnClick={(e) => {
            if (page === 'list') {
                setPage("contexts")
                scrollRef.current.scrollToEnd()
            } else {
                setPage('list')
                scrollRef.current.scrollTo({ y: 0 })
            }
            setScrollEnabled(true)
        }}
        rightButtonWidth={page === 'list' ? (Platform.OS === "web" ? 95 : Platform.OS === "android" ? 96 : 88) : 50}

    >
        <ScrollView
            ref={scrollRef}
            decelerationRate="fast"
            style={Platform.OS === 'android' || Platform.OS === 'ios' ? styles.containerMobile : styles.container}
            horizontal
            pagingEnabled
            scrollEnabled={scrollEnabled}
            onMomentumScrollEnd={() => {
                setScrollEnabled(false)
            }}
            contentContainerStyle={Platform.OS === 'android' || Platform.OS === 'ios' ? {} : { flex: 1 }}
        >
            <View style={styles.page}>
                <View style={styles.headerContainer}>
                    <Text style={styles.title}>{"Tasks for Later"}</Text>
                    <SearchBar clicked={clicked}
                        searchPhrase={searchPhrase}
                        setSearchPhrase={(phrase) => {
                            setSearchPhrase(phrase)
                            if (phrase === "") {
                                resetSearchResultTask()
                            } else {
                                setIsSearchResults(clicked)
                            }
                            debouncedHandleSearchPhraseChange.cancel()
                            debouncedHandleSearchPhraseChange(phrase)
                        }}
                        setClicked={(clickedVal) => {
                            if (shouldUseAllOnNextSearch) {
                                setShouldUseAllOnNextSearch(false)
                                setFilterIndex('all')
                            }
                            setClicked(clickedVal)
                            setIsSearchResults(clickedVal)
                        }}
                        onCancel={() => {
                            setShouldUseAllOnNextSearch(true)
                        }} />
                </View>
                <ContextBubblesTopList
                    isSearchResults={isSearchResults}
                    // @ts-ignore
                    setContextIndex={(index: string) => {
                        setFilterIndex(index)
                        setShouldUseAllOnNextSearch(index === 'all')
                    }}
                    navigation={navigation}
                    contextIndex={filterIndex}
                    marginSide={"left"}
                />

                <ItemsFlatList listType={"SaveForLater"} navigation={navigation} setTaskIds={setTaskIds} extraSpaceAtEnd filterIndex={filterIndex} isSearchResults={isSearchResults} paddingInCell />
                <BottomButton disabled={selectTaskIds.length === 0} singleTap={onButtonPress} text={selectTaskIds.length === 0 ? `Bring to today` : `${selectTaskIds.length} tasks to today`} delay={0} />
                {(filterIndex !== 'contextless' && filterIndex !== 'all') && <IconBottomButton
                    side="left"
                    isIcon
                    singleTap={() => {
                        navigation.navigate('AddTask', { withContext: filterIndex, withType: "SAVE_FOR_LATER" })
                    }}
                >
                    {/* @ts-ignore */}
                    <Svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" height={Platform.OS === 'web' ? 27 : 50} width={Platform.OS === 'web' ? 27 : 50}
                        stroke={cColors.PLUS_ICON_COLOR}
                    >
                        {/* @ts-ignore */}
                        <Path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" strokeWidth="1.5" />
                    </Svg>
                </IconBottomButton>}
                <IconBottomButton
                    side="right"
                    isIcon
                    singleTap={onAddRecentButtonPress}
                    disabled={selectTaskIds.length === 0}
                >
                    <Text style={{ "fontSize": 22 }}>🔄</Text>
                </IconBottomButton>
            </View>
            <ContextsView navigation={navigation} />
        </ScrollView >
    </ModalContainer >
}
const { width } = Dimensions.get('screen')

const styles = StyleSheet.create({
    title: {
        paddingTop: 0,
        paddingBottom: 12,
        fontSize: 24,
        color: cColors.MODAL_TITLE_TEXT_COLOR
    },
    container: {
        flex: 1,
        // width: width,
        backgroundColor: cColors.PAGE_BG_COLOR
    },
    containerMobile: {
        width: width
    },
    page: {
        width: Platform.OS === 'android' || Platform.OS === 'ios' ? width : '100%',
        flex: 1,
        paddingHorizontal: 0,
        display: 'flex',
        backgroundColor: cColors.PAGE_BG_COLOR
    },
    headerContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 14
    },
    categoryContainer: {
        flexDirection: 'row',
        marginBottom: 10,
        height: 70,
    },
    categoryName: {
        color: 'white',
        fontSize: 18
    },
    contextScrollStyle: {
        display: 'flex',
    }
})

import { TasksActions } from "../tasks/tasksActions"
import { UserActions } from "../user/userActions"
import { VisualActions } from "./visualActions"

interface MenuState {
  taskId?: string,
}

const INIT_STATE = {
  focusedTaskId: "",
  disallowSort: false,
  didRequestDailyTasks: false,
  customColor: "white",
  menu: {} as MenuState,
  enableNotification: false
}

export const visualReducer = (state = INIT_STATE, action: { type: string, payload?: any }) => {
  switch (action.type) {
    case TasksActions.SUCCESS_LOAD_JUST_TODAY_TASKS_ACTION:
      return { ...state, disallowSort: action.payload.disallowSort }
    case TasksActions.SUCCESS_LOAD_DAILY_TASKS_ACTION:
      return { ...state, didRequestDailyTasks: true }
    case TasksActions.SET_FOCUS_TASK_ID_ACTION:
      return { ...state, focusedTaskId: action.payload.focusedTaskId }
    case TasksActions.SUCCESS_LOAD_FOCUSED_TASK_ACTION:
      return { ...state, focusedTaskId: action.payload.focusedTaskId }
    case VisualActions.SET_TASK_ID_MENU:
      return { ...state, menu: { taskId: action.payload.taskId } }
    case VisualActions.TOGGLE_ENABLE_NOTIFICATION:
      return { ...state, enableNotification: action.payload.enableNotification }
    case VisualActions.RESET_TASK_ID:
      return { ...state, menu: { taskId: undefined } }
    case UserActions.SUCCESSFUL_LOGOUT_ACTION:
          return {...INIT_STATE}
    default:
      return state
  }
}

import React, { useState } from "react"
import { View, Text, Platform } from "react-native"
import cColors from "../styles/componentColors"
import RoundedButton from "../components/RoundedButton"
import TextInput from "../components/TextInput"
import { HOST } from "../api"
import { styles, inputStyle } from '../styles/signUpInputStyles'
import { emailRegex } from "../utils/validation"

const ResetPasswordScreen = ({ navigation }: { navigation: any }) => {
    const [email, setEmail] = useState("")
    const [response, setResponse] = useState("")
    const [isValid, setIsValid] = useState(false)

    // useEffect(() => {
    // }, []);

    const sendNewPasswordRequest = () => {
        fetch(`${HOST}/auth/reset-password-request`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(resp => resp.json())
            .then((resp) => {
                setResponse(resp.response)
            })
            .catch((e) => {
                setResponse('ok')
            })
    }

    const validateEmailInput = (emailText: string) => {
        let valid = emailRegex.test(emailText)
        setIsValid(valid)
    }

    const validateEmailInputOnBlur = () => {
        validateEmailInput(email)
    }

    return <View style={styles.resetContainer} >
        <View
            style={styles.resetPage}
        >
            <Text style={styles.label}>{"Reset Password"} </Text>
            {response === 'ok'
                ?
                <>
                    <Text style={styles.subLabel}>{"We'll send you a link to reset password."} </Text>
                </>
                :
                <>
                    <Text style={styles.subLabel}>{"Whats your email?"} </Text>
                    <TextInput
                        value={email}
                        autoComplete="off"
                        autoCapitalize="none"
                        autoCorrect={false}
                        style={Platform.OS === 'web' ? inputStyle : styles.input}
                        placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
                        placeholder="Email"
                        onChangeText={(text) => {
                            setEmail(text)
                            validateEmailInput(text)
                        }}
                        numberOfLines={1}
                        onBlur={validateEmailInputOnBlur}
                    />
                    <View style={styles.padding} />
                    <RoundedButton
                        singleTap={() => {
                            sendNewPasswordRequest()
                        }}
                        disabled={!isValid}
                    >
                        <Text style={styles.buttonText}>Send Email</Text>
                    </RoundedButton>
                    <View style={styles.padding} />
                </>}
        </View>
    </View>
}

export default ResetPasswordScreen

import { Context, ContextOrder, MappedContexts } from "../../types/Context"

export enum ContextActions {
    START_SAVE_CONTEXT_ACTION = "START_SAVE_CONTEXT_ACTION",
    SUCCESS_SAVE_CONTEXT_ACTION = "SUCCESS_SAVE_CONTEXT_ACTION",
    FAILED_SAVE_CONTEXT_ACTION = "FAILED_SAVE_CONTEXT_ACTION",

    START_LOAD_CONTEXTS_ACTION = "START_LOAD_CONTEXTS_ACTION",
    SUCCESS_LOAD_CONTEXTS_ACTION = "SUCCESS_LOAD_CONTEXTS_ACTION",
    FAILED_LOAD_CONTEXTS_ACTION = "FAILED_LOAD_CONTEXTS_ACTION",

    START_DELETE_CONTEXT_ACTION = "START_DELETE_CONTEXT_ACTION",
    SUCCESS_DELETE_CONTEXT_ACTION = "SUCCESS_DELETE_CONTEXT_ACTION",
    FAILED_DELETE_CONTEXT_ACTION = "FAILED_DELETE_CONTEXT_ACTION",

    START_UPDATE_CONTEXT_ORDER_ACTION = "START_UPDATE_CONTEXT_ORDER_ACTION",
    SUCCESS_UPDATE_CONTEXT_ORDER_ACTION = "SUCCESS_UPDATE_CONTEXT_ORDER_ACTION",
}

export const startLoadContextsAction = function () {
    return {
        type: ContextActions.START_LOAD_CONTEXTS_ACTION
    }
}

export const successLoadContextsAction = function (contexts: MappedContexts) {
    return {
        type: ContextActions.SUCCESS_LOAD_CONTEXTS_ACTION,
        payload: contexts
    }
}

export const failedLoadContextsAction = function (contexts: MappedContexts) {
    return {
        type: ContextActions.FAILED_LOAD_CONTEXTS_ACTION,
    }
}

export const startSaveContextAction = function () {
    return {
        type: ContextActions.START_SAVE_CONTEXT_ACTION
    }
}

export const successSaveContextAction = function (context: Context) {
    return {
        type: ContextActions.SUCCESS_SAVE_CONTEXT_ACTION,
        payload: context
    }
}

export const failedSaveContextsAction = function (contexts: MappedContexts) {
    return {
        type: ContextActions.FAILED_SAVE_CONTEXT_ACTION
    }
}

export const startDeleteContextAction = function (context_id: string) {
    return {
        type: ContextActions.START_DELETE_CONTEXT_ACTION,
        payload: context_id
    }
}

export const successDeleteContextAction = function (context_id: string) {
    return {
        type: ContextActions.SUCCESS_DELETE_CONTEXT_ACTION,
        payload: context_id
    }
}

export const failedDeleteContextAction = function (context_id: string) {
    return {
        type: ContextActions.FAILED_DELETE_CONTEXT_ACTION,
        payload: context_id
    }
}

export const startUpdateContextOrderAction = function (contextOrder: ContextOrder) {
    return {
        type: ContextActions.START_UPDATE_CONTEXT_ORDER_ACTION,
        payload: contextOrder
    }
}

export const successUpdateContextOrderAction = function (contextOrder: ContextOrder) {
    return {
        type: ContextActions.SUCCESS_UPDATE_CONTEXT_ORDER_ACTION,
        payload: contextOrder
    }
}

export const LOGGER_HOST = "http://logger-dev.up.railway.app"
export default class Logger {

    private token: string;

    constructor(token: string) {
        this.token = token
    }

    public log(type: string, log: string) {
        try {
            fetch(`${LOGGER_HOST}/log`, {
                method: "POST",
                body: JSON.stringify({
                    log_type: type,
                    log_content: log
                }),
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `${this.token}`
                }
            }).then(res => res.json())
        } catch (e) {
            console.log("e", e)
        }
    }
}
import { StyleSheet, Text, View, StatusBar, Button, Pressable, Platform, Dimensions } from 'react-native';
import React from 'react'
import cColors from '../styles/componentColors';
// @ts-ignore
import DoubleClick from 'react-native-double-tap'
import { TouchableWithoutFeedback } from "react-native-gesture-handler"
const BUTTON_WIDTH_HEIGHT = 70
const screenDimensions = Dimensions.get("screen");
const bottonButtonWidth = (screenDimensions.width - 14) / 2
const sideDistance = ((screenDimensions.width - bottonButtonWidth)) / 2
// const iconButtonWidth = (sideDistance) * 0.86
const marginDist = (sideDistance - BUTTON_WIDTH_HEIGHT) / 2


const IconBottomButton = (props: { singleTap: () => void, doubleTap?: () => void, text?: string, delay?: number, side: "left" | "right", children?: any, isIcon?: boolean, disabled?: boolean }) => {
    return <IconButtonContainer side={props.side}>
      <TouchableComponent
        singleTap={props.singleTap}
        doubleTap={props.doubleTap}
        delay={props.delay}
        disabled={props.disabled}
      >
        <View style={props.isIcon ? styles.iconButtonContent : styles.buttonContent}>
          <>
                {props.text && <Text style={styles.buttonText}>{props.text}</Text>}
                {props.children}
          </>
        {props.disabled &&  <View style={styles.overlay} />}
        </View>

      </TouchableComponent>
    </IconButtonContainer>
}

const TouchableComponent  = (props: {disabled?: boolean, children?: any, doubleTap?: () => void, singleTap?: () => void, delay?: number }) => {
  const children = React.Children.only(props.children);

  return props.disabled ?
      <TouchableWithoutFeedback disabled={true}>{props.children}</TouchableWithoutFeedback>
      : <DoubleClick
         singleTap={props.singleTap}
         doubleTap={props.doubleTap}
         delay={props.delay || 250}
       >{children}</DoubleClick>
}

interface IconContainerProps { children: any, side: 'left' | 'right' }

const IconButtonContainerWeb = (props: IconContainerProps) => {
    return <div style={{
        position: 'absolute',
        bottom: 10,
        marginLeft: props.side === 'left' ? "calc(10% - 15px)" : '',
        marginRight: props.side === 'right' ? "calc(10% - 15px)" : '',
        alignSelf: props.side === 'left' ? 'start' : 'end',
        zIndex: 1,
    }}>
        {props.children}
    </div>
}

const IconButtonContainerMobile = (props: IconContainerProps) => {
    return <View style={props.side === 'left' ? styles.buttonLeftContainer : styles.buttonRightContainer}>{props.children}</View>
}

const IconButtonContainer = Platform.select({
    web: IconButtonContainerWeb,
    default: IconButtonContainerMobile,
    native: IconButtonContainerMobile
})

const styles = StyleSheet.create({
    buttonLeftContainer: {
        position: 'absolute',
        bottom: Platform.OS === 'web' ? 10 : 30,
        alignSelf: 'flex-start',
        marginLeft: marginDist,
        width: Platform.OS === 'web' ? "12%" : undefined,
    },
    buttonRightContainer: {
        position: 'absolute',
        bottom: Platform.OS === 'web' ? 10 : 30,
        right: marginDist,
        width: Platform.OS === 'web' ? "12%" : undefined
    },
    buttonContent: {
        width: Platform.OS === 'web' ? "100%" : BUTTON_WIDTH_HEIGHT,
        paddingHorizontal: Platform.OS === 'web' ? 14 : undefined,
        paddingVertical: Platform.OS === 'web' ? 14 : undefined,
        backgroundColor: cColors.BOTTOM_BUTTON_COLOR,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        borderRadius: 9999,
        height: BUTTON_WIDTH_HEIGHT,
        shadowColor: cColors.BOTTOM_BUTTON_SHADOW_COLOR,
        shadowOpacity: 0.3,
        shadowOffset: {
            width: 0,
            height: 5
        }
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: 9999,
    },
    iconButtonContent: {
        width: Platform.OS === 'web' ? "100%" : BUTTON_WIDTH_HEIGHT,
        paddingHorizontal: Platform.OS === 'web' ? 30 : undefined,
        paddingVertical: Platform.OS === 'web' ? 10 : undefined,
        backgroundColor: cColors.BOTTOM_BUTTON_COLOR,
        height: BUTTON_WIDTH_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        borderRadius: 9999,
        shadowColor: cColors.BOTTOM_BUTTON_SHADOW_COLOR,
        shadowOpacity: 0.3,
        shadowOffset: {
            width: 0,
            height: 5
        }
    },
    buttonText: {
        color: cColors.BUTTON_TEXT_COLOR,
        fontSize: 20,
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

export default IconBottomButton

import React from 'react'
import { StyleSheet, View } from 'react-native';
import cColors from '../styles/componentColors';
// @ts-ignore
import DoubleClick from 'react-native-double-tap'
const RoundedButton = ({ singleTap, doubleTap, delay, children, styleType = 'solid', disabled, backgroundColor }: { singleTap: () => void, doubleTap?: () => void, children?: any, delay?: number, styleType?: 'outlined' | 'solid', disabled?: boolean, backgroundColor?: string }) => {
    const ButtonStyle = styleType === 'solid' ? disabled ? styles.disabledButtonSolidContent : styles.buttonSolidContent : styles.buttonOutlineContent
    const ButtonStyleWBGColor = backgroundColor ? { ...ButtonStyle, backgroundColor } : ButtonStyle;
    
    return <View
        style={styles.buttonContainer}
    >
        {!disabled
            ? <DoubleClick
                singleTap={singleTap}
                doubleTap={doubleTap}
                delay={delay || 250}
            >
                <View style={ButtonStyleWBGColor}>
                    {children}
                </View>
            </DoubleClick>
            : <View style={ButtonStyleWBGColor}>
                {children}
            </View>}
    </View>
}

const styles = StyleSheet.create({
    buttonContainer: {
        alignSelf: 'center',
    },
    disabledButtonSolidContent: {
        backgroundColor: cColors.ROUNDED_BUTTON_DISABLED_COLOR,
        paddingVertical: 10,
        width: 220,
        borderColor: 'white',
        borderRadius: 9999,
        paddingHorizontal: 8
    },
    buttonSolidContent: {
        backgroundColor: cColors.ROUNDED_BUTTON_SOLID_COLOR,
        paddingVertical: 10,
        width: 220,
        borderColor: cColors.ROUNDED_BUTTON_SOLID_BORDER_COLOR,
        borderRadius: 9999,
        paddingHorizontal: 8
    },
    buttonOutlineContent: {
        paddingVertical: 10,
        width: 220 - 4,
        borderColor: cColors.ROUNDED_BUTTON_OUTLINE_BORDER_COLOR,
        borderRadius: 9999,
        borderWidth: 2,
        paddingHorizontal: 8
    }
});
export default RoundedButton
import { DateTime } from 'luxon';

const BACKEND_DATE_FORMAT = "yyyy-LL-dd"
export const getNow = () => {
    return DateTime.now()
}

export const parseCurrentDateTime = () => {
    return DateTime.now().toFormat('LLLL dd, yyyy')
}

export const parseCurrentDateTimeIso = () => {
    return DateTime.now().toFormat(BACKEND_DATE_FORMAT)
}

export const parseYesterdayDateTimeIso = () => {
    return DateTime.now().minus({ days: 1 }).toFormat(BACKEND_DATE_FORMAT)
}
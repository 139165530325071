import React, { useState, useEffect } from "react"
import { SafeAreaView, View, Text, Platform } from "react-native"
import cColors from "../styles/componentColors"
import RoundedButton from "../components/RoundedButton"
import TextInput from "../components/TextInput"
import { HOST } from "../api"
import { styles, inputStyle } from '../styles/signUpInputStyles'
import { passwordRegex } from "../utils/validation"

const PASSWORD_CRITERIA = `- At least one uppercase letter
- At least one lowercase letter
- At least one digit
- At least one special character from the set [!@#$%^&*-]
- Allowed characters are letters (both uppercase and lowercase), digits, and the special characters [!@#$%^&*-]
- Minimum length of 8 characters
`

const SetNewPasswordScreen = ({ navigation }: { navigation: any }) => {
    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")
    const [key, setKey] = useState("")
    const [pageState, setState] = useState<'none' | 'success' | 'error' | 'ready' | 'invalid'>('none');
    const [error, setError] = useState("")
    const [isValid, setIsValid] = useState(false)
    // const [validPW, setValidPW] = useState(true)
    // const [validPWAgain, setValidPWAgain] = useState(true)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const resetPWId = urlParams.get('k');
        setKey(resetPWId as string)
        fetch(`${HOST}/auth/validate-request`, {
            method: 'POST',
            body: JSON.stringify({ key: resetPWId }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(resp => resp.json())
            .then((resp) => {
                if (resp.is_valid) {
                    setState('ready')
                } else {
                    setState('invalid')
                }
            })
            .catch(() => {
                setState('error')
            })
    }, []);

    const validatePWInputs = (passwordText: string, passwordAgainText: string) => {
        let validPW = passwordRegex.test(passwordText);
        let validPWAgain = passwordRegex.test(passwordAgainText);
        let isSame = passwordText === passwordAgainText;

        // console.log(passwordText, passwordText)
        // console.log(validPW, validPWAgain, isSame)

        if (validPW && validPWAgain && isSame) {
            setError("")
            setIsValid(true)
        } else if ((!validPWAgain) || (!validPW)) {
            setError("Password does not meet criteria");
            setIsValid(false)
        } else if (validPW && validPWAgain && !isSame) {
            setError("Passwords do not match");
            setIsValid(false)
        }
    }

    const validatePWInputsOnBlur = () => {
        validatePWInputs(password, passwordAgain)
    }

    const setNewPassword = () => {
        fetch(`${HOST}/auth/set-new-password`, {
            method: 'POST',
            body: JSON.stringify({ key: key, new_password: password }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(resp => resp.json())
            .then((resp) => {
                setState('success')
            })
            .catch(() => {
                setState('error')
            })
    }

    return <SafeAreaView style={styles.resetContainer} >
        <View
            style={styles.resetPage}
        >
            <Text style={styles.label}>{"Set New Password"} </Text>
            {pageState === 'ready' && <>
                <Text style={styles.subLabel}>{"Update your password with the following rules"} </Text>
                <Text style={{ color: cColors.PW_REQUIREMENT_LABEL_COLOR, paddingHorizontal: 12, paddingVertical: 8 }}>{PASSWORD_CRITERIA}</Text>
                <TextInput
                    value={password}
                    autoComplete="off"
                    autoCapitalize="none"
                    autoCorrect={false}
                    style={Platform.OS === 'web' ? inputStyle : styles.input}
                    placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
                    placeholder="New Password"
                    onChangeText={(text) => {
                        setPassword(text)
                        validatePWInputs(text, passwordAgain)
                    }}
                    numberOfLines={1}
                    onBlur={validatePWInputsOnBlur}
                    secureTextEntry={true}
                    isNewPassword
                />
                <TextInput
                    value={passwordAgain}
                    autoComplete="off"
                    autoCapitalize="none"
                    autoCorrect={false}
                    style={Platform.OS === 'web' ? inputStyle : styles.input}
                    placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
                    placeholder="New Password again"
                    onChangeText={(text) => {
                        setPasswordAgain(text)
                        validatePWInputs(password, text)
                    }}
                    numberOfLines={1}
                    onBlur={validatePWInputsOnBlur}
                    secureTextEntry={true}
                    isNewPassword
                />

                {error.length > 0
                    ? <Text style={styles.errorLabel}>{error}</Text>
                    : <View style={{ paddingVertical: 22 }} />
                }

                <RoundedButton
                    singleTap={() => {
                        setNewPassword()
                    }}
                    disabled={!isValid}
                >
                    <Text style={styles.buttonText}>Set New Password</Text>
                </RoundedButton>

                <View style={styles.padding} />
            </>}
            {pageState === 'success' && <>
                <Text style={styles.subLabel}>{"New password set"} </Text>
            </>}
            {pageState === 'error' && <>
                <Text style={styles.subLabel}>{"Error occured"} </Text>
            </>}

        </View>
        <View style={styles.padding} />

    </SafeAreaView>
}

export default SetNewPasswordScreen

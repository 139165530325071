import React, {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  useEffect,
  useRef,
  useState
} from "react";
import {
  useFloating,
  autoUpdate,
  flip,
  offset,
  shift,
  useRole,
  useDismiss,
  useInteractions,
  useListNavigation,
  useTypeahead,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay
} from "@floating-ui/react";
import { Text, View } from 'react-native'
import cColors from "../styles/componentColors";
import { delay } from "../utils/delay"

const styles = {
  ContextMenu: {
    outline: 0,
    background: cColors.CONTEXT_MENU_BG_COLOR,
    border: `1px solid ${cColors.CONTEXT_MENU_BORDER_COLOR}`,
    boxShadow: "0 2px 4px rgba(255, 255, 255, 0.2)",
    padding: "4px",
    borderRadius: "8px"
  },
  MenuItem: {
    width: "100%",
    display: "block",
    // display: "flex",
    justifyContent: "space-between",
    // width: "100%",
    background: cColors.CONTEXT_MENU_ITEM_BG_COLOR,
    border: "solid 1px",
    borderColor: "transparent",
    borderRadius: 4,
    fontSize: "16px",
    textAlign: "left",
    lineHeight: 1.5,
    margin: 0,
    outline: 0,
    color: cColors.CONTEXT_MENU_ITEM_TEXT_COLOR
  },
  MenuItemTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    border: "none",
    borderRadius: 4,
    fontSize: "16px",
    textAlign: "left",
    lineHeight: 1.5,
    margin: 0,
    outline: 0,
    paddingHorizontal: 5.5,
    paddingVertical: 11
  },
  MenuItemTitleText: {
    color: cColors.CONTEXT_MENU_TITLE_TEXT_COLOR,
    textAlign: "left",
    lineHeight: 1.5,
    fontSize: "16px",
  },
  MenuItemHover: {
    background: cColors.CONTEXT_MENU_ITEM_HOVER_BG_COLOR, //cColors.GAINSBORO,
    color: 'black',
    borderColor: cColors.CONTEXT_MENU_ITEM_HOVER_BORDER_COLOR, //colors.GAINSBORO_MINUS_2,
    border: "solid 1px"
  },
  MenuSeperator: {
    flex: 1,
    borderTopWidth: 1,
    borderColor: cColors.CONTEXT_MENU_SEPERATOR_COLOR,
    marginTop: 6,
    marginBottom: 6
  }
}

export const MenuItem = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    label: string;
    disabled?: boolean;
    delay?: number
  }
>(({ label, disabled, ...props }, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <button
      {...props}
      className="MenuItem"
      style={isHovered ? { ...styles.MenuItem, ...styles.MenuItemHover } : styles.MenuItem}
      ref={ref}
      role="menuitem"
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {label}
    </button>
  );
});

const TITLE_CUTOFF = 12

export const MenuItemTitle = forwardRef(({ label, ...props }: { label: string; }, ref) => {
  return (
    <View
      ref={ref as any}
      style={styles.MenuItemTitle}
    >
      <Text
        style={styles.MenuItemTitleText}
        {...props}
      >
        {label.trimEnd().length > TITLE_CUTOFF ? `${label.substring(0, TITLE_CUTOFF).trimEnd()}...` : label}
      </Text>
    </View>

  );
});

export const Seperator = forwardRef(({ ...props }: {}, ref) => {
  return (
    <View
      {...props}
      ref={ref as any}
      style={styles.MenuSeperator}
    >
    </View>

  );
});

interface Props {
  label?: string;
  nested?: boolean;
  taskId?: string;
  resetTaskId: () => void
}

const MenuContainer = forwardRef<HTMLButtonElement, Props & React.HTMLProps<HTMLButtonElement>>(({ children, resetTaskId, taskId }, forwardedRef) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const listItemsRef = useRef<Array<HTMLButtonElement | null>>([]);
  const listContentRef = useRef(
    Children.map(children, (child) =>
      isValidElement(child) ? child.props.label : null
    ) as Array<string | null>
  );
  const allowMouseUpCloseRef = useRef(false);

  const { x, y, refs, strategy, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({ mainAxis: 5, alignmentAxis: 4 }),
      flip({
        fallbackPlacements: ["left-start"]
      }),
      shift({ padding: 10 })
    ],
    placement: "right-start",
    strategy: "fixed",
    whileElementsMounted: autoUpdate
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context);
  const listNavigation = useListNavigation(context, {
    listRef: listItemsRef,
    onNavigate: setActiveIndex,
    activeIndex
  });
  const typeahead = useTypeahead(context, {
    enabled: isOpen,
    listRef: listContentRef,
    onMatch: setActiveIndex,
    activeIndex
  });

  const { getFloatingProps, getItemProps } = useInteractions([
    role,
    dismiss,
    listNavigation,
    typeahead
  ]);

  useEffect(() => {
    let timeout: number;

    function isTaskItem(node: HTMLElement) {
      if (node.classList.contains("TaskItem")) {

        return true
      }
      const taskItems = document.getElementsByClassName("TaskItem")
      for (let taskItem of taskItems) {
        if (taskItem.contains(node)) {
          return true
        }
      }
      return false
    }

    function onContextMenu(e: MouseEvent) {
      e.preventDefault();
      refs.setPositionReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: e.clientX,
            y: e.clientY,
            top: e.clientY,
            right: e.clientX,
            bottom: e.clientY,
            left: e.clientX
          };
        }
      });

      if (!isTaskItem(e.target as HTMLElement)) {
        resetTaskId()
      }

      setIsOpen(true);

      clearTimeout(timeout);

      allowMouseUpCloseRef.current = false;
      timeout = window.setTimeout(() => {
        allowMouseUpCloseRef.current = true;
      }, 300);
    }

    function onMouseUp() {
      if (allowMouseUpCloseRef.current) {
        setIsOpen(false);
        resetTaskId()
      }
    }

    document.addEventListener("contextmenu", onContextMenu);
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("contextmenu", onContextMenu);
      document.removeEventListener("mouseup", onMouseUp);
      clearTimeout(timeout);
    };
  }, [refs]);

  return (
    <FloatingPortal>
      {isOpen && (
        <FloatingOverlay lockScroll>
          <FloatingFocusManager context={context} initialFocus={refs.floating}>
            <div
              className="ContextMenu"
              ref={refs.setFloating}
              style={{
                position: strategy,
                left: x ?? 0,
                top: y ?? 0,
                ...styles.ContextMenu
              }}
              {...getFloatingProps()}
            >
              {Children.map(
                children,
                (child, index) =>
                  isValidElement(child) &&
                  cloneElement(
                    child,
                    getItemProps({
                      tabIndex: activeIndex === index ? 0 : -1,
                      ref(node: HTMLButtonElement) {
                        listItemsRef.current[index] = node;
                      },
                      onClick() {
                        child.props.onClick?.();
                        if (child.props.delay) {
                          delay(child.props.delay)
                            .then(() => {
                              resetTaskId()
                              setIsOpen(false);
                            })
                        } else {
                          resetTaskId()
                          setIsOpen(false);
                        }
                      },
                      onMouseUp() {
                        child.props.onClick?.();
                        setIsOpen(false);
                        resetTaskId()
                      }
                    })
                  )
              )}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
});

export default MenuContainer

import * as React from 'react';
import { View, StyleSheet, ScrollView, Platform, Dimensions } from "react-native"
import TaskInformationView from '../components/TaskInformationView';
import Task from '../types/Task';
import { loadAllTasks, saveTask } from '../services/tasksService';
import ModalContainer from '../components/ModalContainer';
import { CreateMulitpleTasksForm } from '../components/CreateMulitpleTasksForm';
import cColors from '../styles/componentColors';
const { width } = Dimensions.get('screen')

export interface Page {
    task: Task,
    taskViewRef: any
}

export default ({ navigation }: { navigation: any }) => {
    const scrollRef = React.useRef() as React.MutableRefObject<any>;
    const [pages, setPages] = React.useState<Page[]>([])
    const addTaskToPages = (task: Task) => {
        const pagesCopy = [...pages]
        pagesCopy.push({
            task,
            taskViewRef: React.createRef()
        })
        setPages(pagesCopy)
    }
    const deletePage = (index: number) => {
        pages.splice(index, 1)
        setPages([...pages])
    }

    const saveAllTasks = async (finalTask?: Task) => {
        if (pages.length === 0 && finalTask) {
            await saveTask(finalTask)
            navigation.goBack()
            loadAllTasks()
        }
        for (let i = 0; i < pages.length; i++) {
            try {
                pages[i].taskViewRef.current.createNewTask()

                if (i === pages.length - 1) {
                    if (finalTask) {
                        await saveTask(finalTask)
                    }
                    navigation.goBack()
                    loadAllTasks()
                }
            } catch (e) {
                // console.log(e)

            }
        }
    }

    const editTitle = (i: number, title: string) => {
        const newTask = { ...pages[i].task }
        newTask.title = title
        const pagesCopy = [...pages]
        pagesCopy[i].task = newTask
        setPages(pagesCopy)
        pages[i].taskViewRef.current.setNewTitle(title)
    }

    React.useEffect(() => {
        if (scrollRef.current) {
            if (Platform.OS === 'web') {
                scrollRef.current.children[0].style.width = '100%'
                scrollRef.current.children[0].children[0].style.width = "100%"
                for (let i = 0; i < pages.length; i++) {
                    scrollRef.current.children[0].children[i + 1].style.width = "100%"
                }
            }
        }
        return () => { }
    }, [pages, scrollRef])

    return (<ModalContainer navigation={navigation}>
        <ScrollView
            ref={scrollRef}
            style={styles.container}
            horizontal
            pagingEnabled
        >
            <CreateMulitpleTasksForm addTaskToPages={addTaskToPages} pages={pages} createAllTasks={saveAllTasks} editTitle={editTitle} deletePage={deletePage} />

            {pages.map((page, i) => <View
                key={i}
                style={styles.page}>
                <TaskInformationView
                    ref={page.taskViewRef}
                    index={i}
                    navigation={navigation}
                    mode="multi-create"
                    afterButtonPress={() => {
                        saveAllTasks()
                    }}
                    saveTask={(task) => saveTask(task)}
                    task={page.task} /></View>)}
        </ScrollView>
    </ModalContainer>
    )
}


const styles = StyleSheet.create({
    header: {
        paddingTop: 0,
        paddingBottom: 12,
        fontSize: 22,
        color: cColors.HEADER_TEXT_COLOR
    },
    container: {
        backgroundColor: cColors.PAGE_BG_COLOR,
        flex: 1
    },
    page: {
        backgroundColor: cColors.PAGE_BG_COLOR,
        flex: 1,
        width: Platform.OS === 'web' ? "100%" : width,
        paddingTop: 0
    },
    text: {
        color: 'white'
    },
    headerContainer: {
        paddingVertical: 6
    }
})
import { MappedContexts } from "../../types/Context"

export enum PublicContextActions {
    START_PUBLIC_LOAD_CONTEXTS_ACTION = "START_PUBLIC_LOAD_CONTEXTS_ACTION",
    SUCCESS_PUBLIC_LOAD_CONTEXTS_ACTION = "SUCCESS_PUBLIC_LOAD_CONTEXTS_ACTION",
    FAILED_PUBLIC_LOAD_CONTEXTS_ACTION = "FAILED_PUBLIC_LOAD_CONTEXTS_ACTION",
}

export const startLoadContextsAction = function () {
    return {
        type: PublicContextActions.START_PUBLIC_LOAD_CONTEXTS_ACTION
    }
}

export const getPublicContextSuccessAction = function (contexts: MappedContexts) {
    return {
        type: PublicContextActions.SUCCESS_PUBLIC_LOAD_CONTEXTS_ACTION,
        payload: contexts
    }
}

export const failedLoadContextsAction = function () {
    return {
        type: PublicContextActions.FAILED_PUBLIC_LOAD_CONTEXTS_ACTION,
    }
}
